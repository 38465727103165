//#region header */
/**************************************************************************************************
//
//  Description: DashboardFormattingUtils
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   07.05.21 Peter Bryden       WI39345 Initial Revision.
//    002   10.05.21 Sean Flook         WI39345 Added formatExpiryReviewDate.
//    003   10.05.21 Sean Flook         WI39345 Tweaked getTypeIcon to use the row data.
//    004   12.05.21 Sean Flook         WI39345 Corrected icons, renamed functions and various additional tweaks.
//    005   14.05.21 Sean Flook         WI39345 Commented out debug message.
//    006   09.06.21 Sean Flook         WI39345 Fixed FormatDate to correctly work in XdmRecordForm.
//    007   10.06.21 Sean Flook         WI39345 Added FormatAddressToTitleCase.
//    008   23.06.21 Sean Flook         WI39345 Handle when we do not have a date.
//    009   28.06.21 Sean Flook         WI39345 Undo change that prevented last update from being displayed.
//    010   13.10.21 Sean Flook         WI39823 Changed to use formattedAddress.
//    011   20.10.21 Sean Flook         WI39823 Handle when we do not have an address.
//    012   17.08.22 Joel Benford       WI39920 Change Miscellaneous icon
//    013   25.08.22 Joel Benford       WI40269 Tidy Console.logs
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */

import Typography from "@material-ui/core/Typography";
import RiskIcon from "@material-ui/icons/PriorityHigh";
import PeopleIcon from "@material-ui/icons/People";
import LocationIcon from "@material-ui/icons/Room";
import MiscellaneousIcon from "@material-ui/icons/Storage";
import WarningIcon from "@material-ui/icons/Warning";
import useStyles from "../../utils/AAStyles";

/* #endregion imports */

function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

function getDayString(day) {
  switch (day) {
    case 0:
      return "Sun";
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thu";
    case 5:
      return "Fri";
    default:
      return "Sat";
  }
}

function getMonthString(month) {
  switch (month) {
    case 0:
      return "Jan";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "Jun";
    case 6:
      return "Jul";
    case 7:
      return "Aug";
    case 8:
      return "Sep";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    default:
      return "Dec";
  }
}

export function GetTodaysDate() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  // console.log("DEBUG getTodaysDate", yyyy + "-" + mm + "-" + dd + "T00:00:00");
  return yyyy + "-" + mm + "-" + dd + "T00:00:00";
}

export function FormatExpiryReviewDate(params) {
  const classes = useStyles();

  if (params && params.value) {
    const paramsDate = new Date(params.value);
    const luDay = paramsDate.getDate();
    const luMonth = paramsDate.getMonth();
    const luYear = paramsDate.getFullYear();

    const today = GetTodaysDate();
    const dd = String(paramsDate.getDate()).padStart(2, "0");
    const mm = String(paramsDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = paramsDate.getFullYear();
    const overdue = yyyy + "-" + mm + "-" + dd + "T00:00:00" <= today;

    if (overdue)
      return (
        <Typography color="error" variant="body2" display="inline">
          {`${luDay} ${getMonthString(luMonth)} ${String(luYear)}`}
          <WarningIcon className={classes.dashboardOverdueIcon} />
        </Typography>
      );
    else return `${luDay} ${getMonthString(luMonth)} ${String(luYear)}`;
  }
}

export function FormatDate(params) {
  // console.log("DEBUG FormatDate", params);

  if (params) {
    const today = new Date();
    const todayDay = today.getDate();
    const todayMonth = today.getMonth();
    const todayYear = today.getFullYear();
    // console.log("DEBUG FormatDate today", todayDay, todayMonth, todayYear);

    let paramsDate;
    if (params.value) paramsDate = new Date(params.value);
    else paramsDate = new Date(params);
    const luDay = paramsDate.getDate();
    const luMonth = paramsDate.getMonth();
    const luYear = paramsDate.getFullYear();
    // console.log("DEBUG FormatDate params", luDay, luMonth, luYear);

    // if param dates is today show AM/PM time
    if (luDay === todayDay && luMonth === todayMonth && luYear === todayYear)
      return formatAMPM(paramsDate);

    //if param date is in the same month and year, but not today
    if (luMonth === todayMonth && luYear === todayYear)
      return `${getDayString(paramsDate.getDay())} ${luDay} ${getMonthString(
        luMonth
      )}`;

    //if param date is same year but not same month
    if (luYear === todayYear) return `${luDay} ${getMonthString(luMonth)}`;

    return `${luDay} ${getMonthString(luMonth)} ${String(luYear)}`;
  } else {
    return "";
  }
}

export function FormatAddressToTitleCase(params) {
  //console.log("DEBUG FormatAddressToTitleCase", params);
  if (params && params.value) {
    return (
      params.value
        .replace(params.row.postcode, "")
        .replace(/[\r\n]/gm, ", ")
        .replaceAll(", , ", ", ")
        .replaceAll(", , ", ", ")
        .replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }) + params.row.postcode
    );
  } else {
    return "";
  }
}

export function GetTypeIcon(params) {
  if (params) {
    // console.log("DEBUG getTypeIcon", params.row.xdmType);
    switch (params.row.xdmType) {
      case "Risk":
        return <RiskIcon className="dashboardIcon" />;
      case "People":
        return <PeopleIcon className="dashboardIcon" />;
      case "Location":
        return <LocationIcon className="dashboardIcon" />;
      default:
        return <MiscellaneousIcon className="dashboardIcon" />;
    }
  } else {
    return "";
  }
}
