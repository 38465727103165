//#region header */
/**************************************************************************************************
//
//  Description: Main XDM Layout page
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//	  003   09.03.22 Peter Bryden       WI40103 Added in Symphony API Security Authentication
//    004   25.08.22 Joel Benford       WI40269 Tidy Console.logs
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React, { useState, useEffect, useContext } from "react";
import CurrentUserContext from "../context/currentUserContext";
import useStyles from "../utils/AAStyles";
import Grid from "@material-ui/core/Grid";
import XdmDataForm from "../forms/XdmDataForm";
import AAEsriMap from "../components/AAEsriMap";
import { GetXdmFromUPRN, authBearerHeader } from "../configuration/AAConfig";
import PropertyContext from "../context/propertyContext";
import { ConfirmationServiceProvider } from "./EditConfirmationPage";
/* #endregion imports */

function Xdmpage() {
  const classes = useStyles();
  const propertyContext = useContext(PropertyContext);
  const userContext = useContext(CurrentUserContext);
  const userToken = userContext.currentUser
    ? userContext.currentUser.token
    : null;
  const [apiURL, setApiURL] = useState(null);
  const [data, setData] = useState();
  const [xdmData, setXdmData] = useState({
    risk: [],
    people: [],
    location: [],
    miscellaneous: [],
  });
  const [dataUprn, setDataUprn] = useState(
    propertyContext.currentProperty.uprn
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function SetUpApi() {
      const xdmUrl = await GetXdmFromUPRN();
      setApiURL(xdmUrl);
    }

    async function SetUpXdmData() {
      if (!data || propertyContext.currentProperty.uprn !== dataUprn) {
        if (apiURL) {
          // console.log(
          //   "DEBUG fetching",
          //   dataUprn,
          //   `${apiURL.url}/${propertyContext.currentProperty.uprn}`
          // );
          setLoading(true);
          fetch(`${apiURL.url}/${propertyContext.currentProperty.uprn}`, {
            headers: authBearerHeader(userToken, true),
            crossDomain: true,
            method: "GET",
          })
            .then((res) => (res.ok ? res : Promise.reject(res)))
            .then((res) => res.json())
            .then(
              (result) => {
                //console.log("DEBUG SetUpXdmData", result);
                setData(result);
                setXdmData({
                  risk: result.filter(
                    (xdm) => xdm.dataType === 4 || xdm.dataType === "Risk"
                  ),
                  people: result.filter(
                    (xdm) => xdm.dataType === 3 || xdm.dataType === "People"
                  ),
                  location: result.filter(
                    (xdm) => xdm.dataType === 1 || xdm.dataType === "Location"
                  ),
                  miscellaneous: result.filter(
                    (xdm) =>
                      xdm.dataType === 2 || xdm.dataType === "Miscellaneous"
                  ),
                });
                // console.log(
                //   "DEBUG SetUpXdmData - Risk",
                //   result.filter(
                //     (xdm) => xdm.dataType === 4 || xdm.dataType === "Risk"
                //   )
                // );
                // console.log(
                //   "DEBUG SetUpXdmData - People",
                //   result.filter(
                //     (xdm) => xdm.dataType === 3 || xdm.dataType === "People"
                //   )
                // );
                // console.log(
                //   "DEBUG SetUpXdmData - Location",
                //   result.filter(
                //     (xdm) => xdm.dataType === 1 || xdm.dataType === "Location"
                //   )
                // );
                // console.log(
                //   "DEBUG SetUpXdmData - Miscellaneous",
                //   result.filter(
                //     (xdm) =>
                //       xdm.dataType === 2 || xdm.dataType === "Miscellaneous"
                //   )
                // );
              },
              (error) => {
                console.log("ERROR Get XDM data", error);
              }
            )
            .then(() => {
              setDataUprn(propertyContext.currentProperty.uprn);
              setLoading(false);
            });
        } else {
          //console.log("DEBUG apiURL is null");
        }
      }
    }

    if (!apiURL) SetUpApi();

    SetUpXdmData();

    return () => {};
  }, [xdmData, propertyContext, apiURL, data, dataUprn, userToken]);

  return data && !loading ? (
    <ConfirmationServiceProvider>
      <div className={classes.xdmPage}>
        <Grid container justify="flex-start" spacing={0}>
          <Grid item xs={12}>
            <Grid container spacing={0} justify="flex-start">
              <Grid item xs={12} sm={4}>
                <XdmDataForm data={xdmData} />
              </Grid>
              <Grid item xs={12} sm={8}>
                <AAEsriMap />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </ConfirmationServiceProvider>
  ) : (
    <h6>Loading XDM Page</h6>
  );
}

export default Xdmpage;
