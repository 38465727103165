/* #region header */
/**************************************************************************************************
//
//  Description: Handle Page Routing
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue#  Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook          WI39345 Initial Revision.
//    002   21.06.21 Joel Benford        WI39518 Add login page
//    003   28.06.21 Sean Flook          WI39345 Corrected typo.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CurrentUserContext from "./context/currentUserContext";

import Homepage from "./pages/Homepage";
import Xdmpage from "./pages/Xdmpage";
import UserAdminpage from "./pages/UserAdminpage";
import Loginpage from "./pages/LoginPage";
/* #endregion imports */

export const HomeRoute = "/";
export const XdmRoute = "/xdm";
export const UserAdminRoute = "/useradmin";
export const LoginRoute = "/login";
export const LogoutRoute = "/logout";

export const CurrentRoute = () => {
  const currentRoute = window.location.pathname;
  // console.log("DEBUG CurrentRoute", currentRoute);

  if (currentRoute.startsWith(XdmRoute)) return XdmRoute;

  return HomeRoute;
};

const ProtectedRoute = ({
  path,
  component: Component,
  render,
  currentUser,
  ...Rest
}) => {
  return (
    <Route
      {...Rest}
      render={(props) => {
        if (!currentUser) return <Redirect to={LoginRoute} />;
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

const PageRouting = () => {
  const currentUser = useContext(CurrentUserContext).currentUser;
  return (
    <Switch>
      <Redirect from={LogoutRoute} to={LoginRoute} />
      <Route path={LoginRoute} component={Loginpage} />
      <ProtectedRoute
        exact
        path={`${XdmRoute}/:uprn`}
        component={Xdmpage}
        currentUser={currentUser}
      />
      <ProtectedRoute
        path={UserAdminRoute}
        component={UserAdminpage}
        currentUser={currentUser}
      />
      <ProtectedRoute
        path="*"
        exact
        component={Homepage}
        currentUser={currentUser}
      />
    </Switch>
  );
};

export default PageRouting;
