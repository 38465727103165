//#region header */
/**************************************************************************************************
//
//  Description: Proxy the SymphonySecurity API, group endpoints by controller
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue#  Description
//#region Version 1.0.0.0 changes
//    001   12.08.21 Joel Benford        WI39518 Initial Revision.
//	  002	09.03.22 Peter Bryden		WI40103	Added in Symphony API Security Authentication
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import {
  GetDeleteUser,
  GetGetUser,
  GetGetUsers,
  GetPostUser,
  GetPostUserLogin,
  GetPutUser,
  GetUpdateAnyUserPassword,
  GetUpdateMyPassword,
    GetWhoAmI,
  authBearerHeader,
} from "../configuration/AAConfig";
/* #endregion imports */


// returns an object including a token which must be passed in heades to other endpoints
const login = async (email, password) => {
  const apiDetails = await GetPostUserLogin();
    const loginResult = await fetch(apiDetails.url, {
        headers: apiDetails.headers,
    mode: apiDetails.mode,
    crossDomain: true,
    method: apiDetails.type,
    body: JSON.stringify({ email: email, password: password }),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => res.json())
    .then(
      (result) => {
        return result ? result : {};
      },
      (error) => {
        console.log("ERROR posting credentials to Login", error);
      }
    );
  return loginResult;
};

// apiDetails - as returned by AAConfig
// token - e.g. (useContext(CurrentUserContext)).currentUser.token
// errorMessage - string to report on exception
// params - array of string/stringable to go in REST URL
// body - object to attach to call, will be serialized
const loggedInCall = async (apiDetails, token, errorMessage, params, body) => {
    const finalUrl = apiDetails.url + (params ? params.map((p) => `${p}/`) : "");
  let call = {
    headers: authBearerHeader(token,true),
    mode: apiDetails.mode,
    crossDomain: true,
    method: apiDetails.type,
  };
  if (body) call = { ...call, body: JSON.stringify(body) };
  const apiResult = await fetch(finalUrl, call)
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => res.json())
    .then(
      (result) => {
        return result ? result : {};
      },
      (error) => {
        console.log(errorMessage, error);
      }
    );
  return apiResult;
};

// AUTHORITY CONTROLLER
const whoAmI = async (token) =>
  await loggedInCall(
    await GetWhoAmI(),
    token,
    "ERROR getting Authority/WhoAmI",
    null,
    null
  );

const updateMyPassword = async (token, newPassword) =>
  await loggedInCall(
    await GetUpdateMyPassword(),
    token,
    "ERROR putting UpdateMyPassword",
    null,
    {
      password: newPassword,
    }
  );

const updateAnyUserPassword = async (token, id, newPassword) =>
  await loggedInCall(
    await GetUpdateAnyUserPassword(),
    token,
    "ERROR putting UpdateAnyUserPassword",
    null,
    {
      id: id,
      password: newPassword,
    }
  );

export const authority = {
  login,
  whoAmI,
  updateMyPassword,
  updateAnyUserPassword,
};

//USER CONTROLLER
const getUsers = async (token) =>
  await loggedInCall(
    await GetGetUsers(),
    token,
    "ERROR getting Users",
    null,
    null
  );

const postUser = async (token, user) =>
  await loggedInCall(
    await GetPostUser(),
    token,
    "ERROR posting user",
    null,
    user
  );

const putUser = async (token, user) =>
  await loggedInCall(
    await GetPutUser(),
    token,
    "ERROR putting user",
    null,
    user
  );

const deleteUser = async (token, user) =>
  await loggedInCall(
    await GetDeleteUser(),
    token,
    "ERROR deleting user",
    null,
    user
  );

const getUser = async (token, id) =>
  await loggedInCall(
    await GetGetUser(),
    token,
    "ERROR getting user",
    `${id}`,
    null
  );

export const user = { getUser, getUsers, postUser, putUser, deleteUser };
