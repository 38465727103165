//#region header */
/**************************************************************************************************
//
//  Description: URL data about the api calls we need to make
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Changes for using a single API and new dashboard end points.
//    003   12.05.21 Sean Flook         WI39345 Added GetOverdueCounts.
//    004   01.06.21 Sean Flook         WI39345 Added authority.
//    005   17.06.21 Sean Flook         WI39345 Added new API calls.
//    006   23.06.21 Joel Benford       WI39518 Add security API
//    007   10.08.21 Joel Benford       WI39518 Switch to new SymphonySecurity API
//    008   13.10.21 Sean Flook         WI39823 Updated search URL.
//    009   09.03.22 Peter Bryden       WI40103 Added in Symphony API Security Authentication
//    010   08.08.22 Joel Benford       WI39945 Config for getting versions
//    011   01.09.22 Joel Benford       WI40273 Drop API keys and Search API
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

var currentConfig = {
  api: "",
  securityApi: "",
  maxRecords: 0,
  template: "",
  authority: 0,
};

const getConfigInfo = () => {
  if (
    !currentConfig ||
    !currentConfig.api ||
    currentConfig.api.length === 0 ||
    !currentConfig.symphonySecurityApi ||
    currentConfig.symphonySecurityApi.length === 0 ||
    !currentConfig.maxRecords ||
    currentConfig.maxRecords === 0 ||
    !currentConfig.template ||
    currentConfig.template.length === 0 ||
    !currentConfig.authority ||
    currentConfig.authority === 0 
  ) {
    //console.log("DEBUG getting .env");
    const api = process.env.REACT_APP_API;
    const symphonySecurityApi = process.env.REACT_APP_SYMPHONY_SECURITY_API;
    const maxRecords = process.env.REACT_APP_MAX_RECORDS;
    const template = process.env.REACT_APP_TEMPLATE;
    const authority = process.env.REACT_APP_AUTHORITY;

    currentConfig = {
      api,
      symphonySecurityApi,
      maxRecords,
      template,
      authority,
    };
  }
};

export function authBearerHeader(token, jsonType = true) {
  // return auth header with jwt if user is logged in and request is to the api url
  //const token = auth?.token;
  const isLoggedIn = !!token;

  let header = {};
  if (jsonType) header["Content-Type"] = "application/json";

  if (isLoggedIn) header["Authorization"] = `Bearer ${token}`;

  return header;
}

export function GetApiSite(urlController) {
  getConfigInfo();
  const ApiUrl = `${currentConfig.api}${urlController}`;
  return ApiUrl;
}

export function GetSecurityApiSite(urlController) {
  getConfigInfo();
  const SecurityApiUrl = `${currentConfig.symphonySecurityApi}${urlController}`;
  return SecurityApiUrl;
}

export async function GetDataTypeCounts() {
  const url = await GetApiSite("/api/DashBoard/GetDataTypeCounts");

  return {
    url: url,
    type: "GET",
    mode: "cors",
    headers: [],
  };
}

export async function GetCategoryCounts() {
  const url = await GetApiSite("/api/DashBoard/GetCategoryCounts");

  return {
    url: url,
    type: "GET",
    mode: "cors",
    headers: [],
  };
}

export async function GetOverdueCounts() {
  const url = await GetApiSite("/api/DashBoard/GetExpiredReviewedCounts");

  return {
    url: url,
    type: "GET",
    mode: "cors",
    headers: [],
  };
}

export async function GetExpiredRecords() {
  const url = await GetApiSite("/api/DashBoard/GetExpiredRecords");

  return {
    url: url,
    type: "GET",
    mode: "cors",
    headers: [],
  };
}

export async function GetReviewRecords() {
  const url = await GetApiSite("/api/DashBoard/GetReviewRecords");

  return {
    url: url,
    type: "GET",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

export async function GetUpdatedRecords() {
  const url = await GetApiSite("/api/DashBoard/GetUpdatedRecords");

  return {
    url: url,
    type: "GET",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

export async function GetSearchURL() {
  const url = await GetApiSite("/api/Search/PatternSearch");

  return {
    url: url,
    type: "GET",
    mode: "cors",
    headers: { "Content-Type": "text/plain" },
    info: currentConfig,
  };
}

export async function GetXdmFromUPRN() {
  const url = await GetApiSite("/api/XdmService/SearchUprn");

  return {
    url: url,
    type: "GET",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

export async function GetXdmUPRNDataTypeCount() {
  const url = await GetApiSite("/api/XdmService/SearchUprnDatatypeCount");

  return {
    url: url,
    type: "GET",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

export async function GetAddXDMDataURL() {
  const url = await GetApiSite("/api/XdmService");

  return {
    url: url,
    type: "POST",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

export async function GetUpdateXDMDataURL() {
  const url = await GetApiSite("/api/XdmService");

  return {
    url: url,
    type: "PUT",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

export async function GetDeleteXDMDataURL() {
  const url = await GetApiSite("/api/XdmService");

  return {
    url: url,
    type: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  };
}

export async function GetPostUserLogin() {
  const url = await GetSecurityApiSite("/api/Authority/Login");
  return {
    url: url,
    type: "POST",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
  };
}

export async function GetWhoAmI() {
  const url = await GetSecurityApiSite("/api/Authority/WhoAmI");
  return {
    url: url,
    type: "GET",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
  };
}

export async function GetUpdateMyPassword() {
  const url = await GetSecurityApiSite("/api/Authority/UpdateMyPassword");
  return {
    url: url,
    type: "PUT",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

export async function GetUpdateAnyUserPassword() {
  const url = await GetSecurityApiSite("/api/Authority/UpdateAnyUserPassword");
  return {
    url: url,
    type: "PUT",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

export async function GetGetUsers() {
  const url = await GetSecurityApiSite("/api/User/User");
  return {
    url: url,
    type: "GET",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

export async function GetPostUser() {
  const url = await GetSecurityApiSite("/api/User/User");
  return {
    url: url,
    type: "POST",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

export async function GetPutUser() {
  const url = await GetSecurityApiSite("/api/User/User");
  return {
    url: url,
    type: "PUT",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

export async function GetDeleteUser() {
  const url = await GetSecurityApiSite("/api/User/User");
  return {
    url: url,
    type: "DELETE",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

export async function GetGetUser() {
  const url = await GetSecurityApiSite("/api/User/User");
  return {
    url: url,
    type: "GET",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

export async function GetTemplate() {
  getConfigInfo();
  return currentConfig.template;
}

export async function GetAuthority() {
  getConfigInfo();
  return currentConfig.authority;
}
