/* #region header */
/**************************************************************************************************
//
//  Description: Dashboard tables form
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   05.05.21 Sean Flook         WI39345 Initial Revision.
//    002   07.05.21 Peter Bryden               Added in updatedData count
//    003   10.05.21 Sean Flook         WI39345 Added variable size count avatars.
//    004   25.05.21 Sean Flook         WI39345 Set the colour for the selected tab text and indicator.
//    005   17.06.21 Sean Flook         WI39345 Use rounded avatars rather than circles so they do not look strange if we have to expand them.
//    006   01.07.21 Sean Flook         WI39345 Bold numbers in avatar.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import DashboardExpired from "../components/tables/DashboardExpired";
import DashboardReviewOverdue from "../components/tables/DashboardReviewOverdue";
import DashboardUpdatedRecently from "../components/tables/DashboardUpdatedRecently";
import useStyles from "../utils/AAStyles";
/* #endregion imports */

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function DashboardTablesForm({ expiredData, reviewData, updatedData }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  // console.log("DEBUG Dashboard Table Expired", expiredData);
  // console.log("DEBUG Dashboard Table Review", reviewData);
  // console.log("DEBUG Dashboard Table Updated", updatedData);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getStyle = (isActive) => {
    return isActive ? { color: "#2A6EBB" } : null;
  };

  return (
    <div className={classes.dashboardTables}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: { background: "#2A6EBB" },
          }}
          textColor="primary"
          variant="standard"
          selectionFollowsFocus
          aria-label="dashboard tables"
        >
          <Tab
            label="Expired"
            style={getStyle(value === 0)}
            icon={
              <Avatar
                variant="rounded"
                className={
                  !expiredData || expiredData.length === 0
                    ? classes.dashboardHiddenIcon
                    : expiredData.length < 10
                    ? classes.dashboardOverdueIcon__small
                    : expiredData.length < 100
                    ? classes.dashboardOverdueIcon__wide
                    : classes.dashboardOverdueIcon__very_wide
                }
              >
                <Typography variant="caption">
                  <strong>{expiredData.length}</strong>
                </Typography>
              </Avatar>
            }
            {...a11yProps(0)}
          />
          <Tab
            label="Review overdue"
            style={getStyle(value === 1)}
            icon={
              <Avatar
                variant="rounded"
                className={
                  !reviewData || reviewData.length === 0
                    ? classes.dashboardHiddenIcon
                    : reviewData.length < 10
                    ? classes.dashboardOverdueIcon__small
                    : reviewData.length < 100
                    ? classes.dashboardOverdueIcon__wide
                    : classes.dashboardOverdueIcon__very_wide
                }
              >
                <Typography variant="caption">
                  <strong>{reviewData.length}</strong>
                </Typography>
              </Avatar>
            }
            {...a11yProps(1)}
          />
          <Tab
            label="Updated recently"
            style={getStyle(value === 2)}
            icon={
              <Avatar className={classes.dashboardHiddenIcon}>
                <Typography variant="caption" />
              </Avatar>
            }
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DashboardExpired tableData={expiredData} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DashboardReviewOverdue tableData={reviewData} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DashboardUpdatedRecently tableData={updatedData} />
      </TabPanel>
    </div>
  );
}

export default DashboardTablesForm;
