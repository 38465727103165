/* #region header */
/**************************************************************************************************
//
//  Description: Ambulance People Categories
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   12.05.21 Sean Flook         WI39345 Added avatar_icon property.
//    003   20.10.21 Sean Flook         WI39823 Ensured we have short hyphens.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

const PeopleCategoryAmbulance = [
  {
    id: 1,
    category: "Community Care Pathway",
    avatar_id: 1,
    avatar_text: "CP",
    avatar_icon: "/images/categories/CommunityCarePathway.svg",
  },
  {
    id: 2,
    category: "End of Life Care",
    avatar_id: 2,
    avatar_text: "EL",
    avatar_icon: "/images/categories/EndOfLifeCare.svg",
  },
  {
    id: 3,
    category: "Information - Frequent Caller",
    avatar_id: 3,
    avatar_text: "IF",
    avatar_icon: "/images/categories/InformationFrequentCaller.svg",
  },
  {
    id: 4,
    category: "Information - Hoax Caller",
    avatar_id: 4,
    avatar_text: "IH",
    avatar_icon: "/images/categories/InformationHoaxCaller/svg",
  },
  {
    id: 5,
    category: "Medical - Anaphylaxis",
    avatar_id: 5,
    avatar_text: "MA",
    avatar_icon: "/images/categories/MedicalAnaphylaxis.svg",
  },
  {
    id: 6,
    category: "Medical - Bariatic",
    avatar_id: 6,
    avatar_text: "MB",
    avatar_icon: "/images/categories/MedicalBariatic.svg",
  },
  {
    id: 7,
    category: "Medical - Laryngectomy/Neck Breather/Tracheostomy",
    avatar_id: 7,
    avatar_text: "ML",
    avatar_icon: "/images/categories/MedicalLaryngectomy.svg",
  },
  {
    id: 8,
    category: "Medical - Other",
    avatar_id: 8,
    avatar_text: "MO",
    avatar_icon: "/images/categories/MedicalOther.svg",
  },
  {
    id: 9,
    category: "Mental Health",
    avatar_id: 9,
    avatar_text: "MH",
    avatar_icon: "/images/categories/MentalHealth.svg",
  },
  {
    id: 10,
    category: "Safeguarding Adult",
    avatar_id: 10,
    avatar_text: "SA",
    avatar_icon: "/images/categories/SafeguardingAdult.svg",
  },
  {
    id: 11,
    category: "Safeguarding Child",
    avatar_id: 11,
    avatar_text: "SC",
    avatar_icon: "/images/categories/SafeguardingChild.svg",
  },
  {
    id: 12,
    category: "Vulnerable Person",
    avatar_id: 12,
    avatar_text: "VP",
    avatar_icon: "/images/categories/VulnerablePerson.svg",
  },
];

export default PeopleCategoryAmbulance;
