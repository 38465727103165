/* #region header */
/**************************************************************************************************
//
//  Description: Features related to getting metadata
//
//  Copyright:    � 2022 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   08.08.22 Joel Benford       WI39945 Initial version, get version info from API
//#endregion Version 1.0.0.0 changes
//**************************************************************************************************/
/* #endregion header */

//import * as search from "../services/symphonySearchApi";

const updateVersionInfo = async (existingMetadata) => {
  //console.log("DEBUG existingMetadata", existingMetadata);
  const newMetadata = { ...existingMetadata };
  newMetadata.versionInfo.apiVersion = "5.0.0.0.0.0 hc";
  newMetadata.versionInfo.xdmDatabaseVersion = "5.0.0.0.0.0 hc";
  newMetadata.versionInfo.bluelightDatabaseVersion = "5.0.0.0.0.0 hc";
  newMetadata.versionInfo.bluelightIExchangeDatabaseVersion = "5.0.0.0.0.0 hc";
  newMetadata.versionInfo.bluelightElasticIndexMetadata =
    //await search.metadata.getMetadata();
    {
      epochversion: "EPOCH 90",
      bluelightversion: "5.5.6.7.0.0",
      imanageversion: null,
      dbserver: "Rome",
      dbname: "EPOCH_90",
      builton: "02/02/2022 08:10:24",
      elasticnugetversion: "0.0.8.44 hcb",
    };
  newMetadata.versionInfo.xdmElasticIndexMetadata =
    //await search.metadata.getMetadata();
    {
      epochversion: "EPOCH 90",
      bluelightversion: "5.5.6.7.0.0",
      imanageversion: null,
      dbserver: "Rome",
      dbname: "EPOCH_90",
      builton: "02/02/2022 08:10:24",
      elasticnugetversion: "0.0.8.44 h",
    };
  return newMetadata;
};

//functions relating to metadata.versionInfo
export const version = { updateFromApi: updateVersionInfo };
