/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Application Bar component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   14.05.21 Sean Flook         WI39345 Display the address and UPRN of current property.
//    004   25.05.21 Sean Flook         WI39345 removed bottom shadow.
//    005   01.06.21 Sean Flook         WI39345 Updated display of title and added highlighting of action icons.
//    006   02.06.21 Sean Flook         WI39345 Replaced the home and close buttons with action buttons.
//    007   08.06.21 Sean Flook         WI39345 Added a divider and changed position of tooltip.
//    008   16.06.21 Sean Flook         WI39345 Replaced divider with pipe character and moved toolbar to the left.
//    009   17.06.21 Sean Flook         WI39345 Added FormattedAddress to property change.
//    010   18.06.21 Sean Flook         WI39345 Corrected spelling.
//    011   13.10.21 Sean Flook         WI39823 Changed to use formattedAddress.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useContext, useState, Fragment } from "react";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import AAActionButton from "./AAActionButton";
import HelpIcon from "@material-ui/icons/HelpOutline";
import PropertyContext from "../context/propertyContext";
import AASearch from "./AASearch";
import AAHelpDrawer from "./AAHelpDrawer";
import { HomeRoute } from "../PageRouting";
import useStyles from "../utils/AAStyles";

/* #endregion imports */

function AAAppBar(props) {
  const classes = useStyles();
  const propertyContext = useContext(PropertyContext);

  const { history } = props;

  const [open, setOpen] = useState(false);
  const [helpHover, setHelpHover] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleHelpMouseEnter = () => {
    setHelpHover(true);
  };

  const handleHelpMouseLeave = () => {
    setHelpHover(false);
  };

  const handleHomeClick = () => {
    propertyContext.onPropertyChange(0, "", "", 0, 0);
    history.push(HomeRoute);
  };

  function addressToTitleCase(str) {
    return (
      str
        .replace(propertyContext.currentProperty.postcode, "")
        .replace(/[\r\n]/gm, ", ")
        .replaceAll(", , ", ", ")
        .replaceAll(", , ", ", ")
        .replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }) + propertyContext.currentProperty.postcode
    );
  }

  return (
    <AppBar
      position="fixed"
      color="white"
      elevation={0}
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar className={classes.appBar__toolbar}>
        {propertyContext.currentProperty &&
        propertyContext.currentProperty.uprn > 0 ? (
          <Fragment>
            <AAActionButton
              variant="home"
              tooltipTitle="Home"
              tooltipPlacement="bottom"
              onClick={handleHomeClick}
            />
            <Tooltip title="UPRN - Address" arrow placement="bottom-start">
              <Typography
                className={classes.appBar__title}
                variant="subtitle1"
                noWrap
                align="left"
              >
                | <strong>{propertyContext.currentProperty.uprn}</strong>
                {addressToTitleCase(
                  ` - ${propertyContext.currentProperty.formattedAddress}`
                )}
              </Typography>
            </Tooltip>
          </Fragment>
        ) : (
          <Typography
            className={classes.appBar__title}
            variant="subtitle1"
            noWrap
            align="left"
          >
            XDM Manager
          </Typography>
        )}
        <div className={classes.appBar__search}>
          <AASearch placeholder="Search…" />
        </div>
        <IconButton
          aria-label="open help drawer"
          onClick={handleDrawerOpen}
          onMouseEnter={handleHelpMouseEnter}
          onMouseLeave={handleHelpMouseLeave}
          className={clsx(open && classes.appBar__hide)}
        >
          <HelpIcon
            className={clsx({
              [classes.actionIcon]: !helpHover,
              [classes.actionIconHover]: helpHover,
            })}
          />
        </IconButton>
      </Toolbar>
      <AAHelpDrawer open={open} handleDrawerClose={handleDrawerClose} />
    </AppBar>
  );
}

export default AAAppBar;
