/* #region header */
/**************************************************************************************************
//
//  Description: Review Overdue dashboard table
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   05.05.21 Sean Flook         WI39345 Initial Revision.
//    002   07.05.21 Peter Bryden               Updated field names and refactored formatting to dashboardFormattingUtils
//    003   10.05.21 Sean Flook         WI39345 Format the Review date
//    004   12.05.21 Sean Flook         WI39345 Renamed functions and added default sorting.
//    005   12.05.21 Sean Flook         WI39345 Go to the property when double clicking on a row.
//    006   25.05.21 Sean Flook         WI39345 Increased width of type column.
//    007   01.06.21 Sean Flook         WI39345 Added postcode to the property context.
//    008   10.06.21 Sean Flook         WI39345 Format the address and right align the dates.
//    009   17.06.21 Sean Flook         WI39345 Added FormattedAddress to property change.
//    010   18.06.21 Sean Flook         WI39345 Corrected spelling.
//    011   01.07.21 Sean Flook         WI39345 Changes for highlighting the row.
//    012   13.10.21 Sean Flook         WI39823 Changed to use formattedAddress.
//    013   20.10.21 Sean Flook         WI39823 Changed field name.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useContext } from "react";
import PropertyContext from "../../context/propertyContext";
import { DataGrid } from "@material-ui/data-grid";
import useStyles from "../../utils/AAStyles";
import {
  GetTypeIcon,
  FormatExpiryReviewDate,
  FormatDate,
  FormatAddressToTitleCase,
} from "../tables/DashboardFormattingUtils";

/* #endregion imports */

const columns = [
  { field: "id", hide: true },
  { field: "uprn", hide: true },
  {
    field: "formattedAddress",
    headerName: "Property",
    flex: 15,
    type: "string",
    renderCell: FormatAddressToTitleCase,
  },
  { field: "xdmType", headerName: "Type", flex: 4, renderCell: GetTypeIcon },
  {
    field: "category",
    headerName: "Category",
    flex: 10,
    type: "string",
    cellClassName: "table-category",
  },
  {
    field: "reviewDate",
    headerName: "Review date",
    flex: 5,
    type: "date",
    cellClassName: "table-date-overdue",
    renderCell: FormatExpiryReviewDate,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "lastUpdated",
    headerName: "Last updated",
    flex: 6,
    type: "dateTime",
    valueFormatter: FormatDate,
    align: "right",
    headerAlign: "right",
  },
  { field: "latitude", hide: true },
  { field: "longitude", hide: true },
  { field: "postcode", hide: true },
];

function DashboardReviewOverdue({ tableData }) {
  const classes = useStyles();
  const propertyContext = useContext(PropertyContext);

  const handleRowClick = (param, event) => {
    // console.log("DEBUG handleRowClick", param, event);

    if (param) {
      propertyContext.onPropertyChange(
        param.row.uprn,
        param.row.formattedAddress,
        param.row.postcode,
        param.row.longitude,
        param.row.latitude
      );
    }
  };

  return (
    <div className={classes.tableDashboard}>
      <DataGrid
        rows={tableData}
        columns={columns}
        className={classes.dashboardTable}
        autoHeight
        pageSize={25}
        sortModel={[{ field: "reviewDate", sort: "asc" }]}
        getRowClassName={(params) => "dashboardRow"}
        onRowClick={handleRowClick}
      />
    </div>
  );
}

export default DashboardReviewOverdue;
