//#region header */
/**************************************************************************************************
//
//  Description: Edit Loss Confirmation Dialog
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   16.06.21 Sean Flook         WI39345 Changes after UX review.
//    003   17.06.21 Sean Flook         WI39345 Set width of dialog.
//    004   18.06.21 Sean Flook         WI39345 Corrected spelling.
//    005   28.06.21 Sean Flook         WI39345 Set the correct colour for the buttons.
//    006   01.07.21 Sean Flook         WI39345 Increased font size.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */

import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import SaveIcon from "@material-ui/icons/Save";
import DiscardIcon from "@material-ui/icons/SettingsBackupRestoreOutlined";
import CloseIcon from "@material-ui/icons/Close";

import useStyles from "../utils/AAStyles";

/* #endregion imports */

const ConfirmEditLossDialog = ({
  isOpen,
  title,
  message,
  handleSaveClick,
  handleReturnClick,
  handleDisposeClick,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="confirm-edit-loss-dialog"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="confirm-edit-loss-dialog">
        {title ? title : "Unsaved changes"}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSaveClick}
          autoFocus
          variant="contained"
          className={classes.dialogButtonContained}
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
        <Button
          onClick={handleDisposeClick}
          className={classes.dialogButton}
          startIcon={<DiscardIcon />}
        >
          Discard
        </Button>
        <Button
          onClick={handleReturnClick}
          className={classes.dialogButton}
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmEditLossDialog;
