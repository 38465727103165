/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Phone component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   14.05.21 Sean Flook         WI39345 Updated className.
//    004   20.05.21 Sean Flook         WI39345 Display a tooltip if required.
//    005   25.05.21 Sean Flook         WI39345 Include required field text if required to tooltip.
//    006   08.06.21 Sean Flook         WI39345 Changed read-only version to a label and altered colour of outline.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import MaskedInput from "react-text-mask";
import TextField from "@material-ui/core/TextField";
import { Typography, Tooltip } from "@material-ui/core";
import useStyles from "../utils/AAStyles";
/* #endregion imports */

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[0]/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

AAPhoneControl.propTypes = {
  label: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  isRequired: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.number,
  errorText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

AAPhoneControl.defaultProps = {
  isEditable: false,
  isRequired: false,
  errorText: "",
};

function AAPhoneControl({
  label,
  isEditable,
  isRequired,
  helperText,
  value,
  errorText,
  onChange,
}) {
  const classes = useStyles();
  const hasError = useRef(false);

  useEffect(() => {
    hasError.current = errorText !== "" && errorText !== null;
  }, [errorText]);

  return (
    <Grid
      container
      justify="flex-start"
      alignItems="center"
      className={classes.formrow}
    >
      <Grid item xs={4}>
        <Typography
          variant="body2"
          color={
            hasError.current ? "error" : isEditable ? "textPrimary" : "initial"
          }
          align="left"
        >
          {`${label}${isRequired ? "*" : ""}`}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        {helperText && helperText.length > 0 ? (
          <Tooltip
            title={
              isRequired
                ? helperText + " This is a required field."
                : helperText
            }
            arrow
            placement="right"
          >
            <TextField
              className={classes.formInput}
              error={hasError.current}
              fullWidth
              disabled={!isEditable}
              required={isRequired}
              variant="outlined"
              size="small"
              margin="dense"
              helperText={hasError.current && errorText}
              inputProps={{
                inputComponent: TextMaskCustom,
              }}
              value={value}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                  focused: classes.outlinedInputFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              onChange={onChange}
            />
          </Tooltip>
        ) : (
          <TextField
            className={classes.formInput}
            error={hasError.current}
            fullWidth
            disabled={!isEditable}
            required={isRequired}
            variant="outlined"
            size="small"
            margin="dense"
            helperText={hasError.current && errorText}
            inputProps={{
              inputComponent: TextMaskCustom,
            }}
            value={value}
            InputProps={{
              classes: {
                root: classes.outlinedInput,
                focused: classes.outlinedInputFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
            onChange={onChange}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default AAPhoneControl;
