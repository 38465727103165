/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets User Avatar component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook                 Initial Revision.
//    002   12.05.21 Sean Flook         WI39345 Removed colour unless cursor is hovering.
//    003   01.06.21 Sean Flook         WI39345 Highlight the icon when the cursor is hovering over it.
//    004   08.09.21 Joel Benford       WI39518 Handle click on the Login/UserAdmin button
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React, { useState } from "react";
import { LoginRoute } from "../PageRouting";
import clsx from "clsx";
import useStyles from "../utils/AAStyles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
/* #endregion imports */

function AAUserAvatar(props) {
  const classes = useStyles();

  const [iconHover, setIconHover] = useState(false);

  const handleMouseEnter = () => {
    setIconHover(true);
  };

  const handleMouseLeave = () => {
    setIconHover(false);
  };

  const handleClick = () => {
    props.handleClick(LoginRoute);
  };

  return (
    <Grid item xs={12}>
      <IconButton
        aria-label="profile"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        size="large"
      >
        <Tooltip title="Login" arrow placement="right">
          <AccountCircleIcon
            fontSize="large"
            className={clsx({
              [classes.actionIcon]: !iconHover,
              [classes.actionIconHover]: iconHover,
            })}
          />
        </Tooltip>
      </IconButton>
    </Grid>
  );
}

export default AAUserAvatar;
