/* #region header */
/**************************************************************************************************
//
//  Description: Police Location Categories
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   12.05.21 Sean Flook         WI39345 Added avatar_icon property.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

const LocationCategoryPolice = [
  {
    id: 1,
    category: "Heritage Site",
    avatar_id: 1,
    avatar_text: "HS",
    avatar_icon: "/images/categories/HeritageSite.svg",
  },
  {
    id: 2,
    category: "Crown",
    avatar_id: 2,
    avatar_text: "C",
    avatar_icon: "/images/categories/Crown.svg",
  },
  {
    id: 3,
    category: "Alarm",
    avatar_id: 3,
    avatar_text: "A",
    avatar_icon: "/images/categories/Alarm.svg",
  },
  {
    id: 4,
    category: "CCTV",
    avatar_id: 4,
    avatar_text: "C",
    avatar_icon: "/images/categories/CCTV.svg",
  },
  {
    id: 5,
    category: "Street Advice",
    avatar_id: 5,
    avatar_text: "SA",
    avatar_icon: "/images/categories/StreetAdvice.svg",
  },
  {
    id: 6,
    category: "Housing Association",
    avatar_id: 6,
    avatar_text: "HA",
    avatar_icon: "/images/categories/HousingAssociation.svg",
  },
  {
    id: 7,
    category: "Miscellaneous",
    avatar_id: 7,
    avatar_text: "M",
    avatar_icon: "/images/categories/MiscellaneousLocation.svg",
  },
];

export default LocationCategoryPolice;
