/* #region header */
/**************************************************************************************************
//
//  Description: Dashboard Chart form
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   05.05.21 Sean Flook         WI39345 Initial Revision.
//    002   14.05.21 Sean Flook         WI39345 Changed grid justify and alignItem and className.
//    003   20.05.21 Sean Flook         WI39345 Removed overdue and added miscellaneous chart.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React from "react";
import Grid from "@material-ui/core/Grid";
import AADoughnutChart from "../components/AADoughnutChart";
import useStyles from "../utils/AAStyles";
/* #endregion imports */

function DashboardChartsForm({
  allData,
  riskData,
  peopleData,
  locationData,
  miscellaneousData,
}) {
  const classes = useStyles();
  // console.log("DEBUG CountCharts All", allData);
  // console.log("DEBUG CountCharts Risk", riskData);
  // console.log("DEBUG CountCharts People", peopleData);
  // console.log("DEBUG CountCharts Location", locationData);
  // console.log("DEBUG CountCharts Miscellaneous", miscellaneousData);

  return (
    <Grid
      container
      className={classes.dashboardCharts}
      direction="row"
      spacing={1}
      justify="space-between"
      alignItems="flex-start"
    >
      {allData && allData.length > 0 && (
        <AADoughnutChart
          chartData={allData}
          title="All types"
          label="type"
          value="count"
        />
      )}
      {riskData && riskData.length > 0 && (
        <AADoughnutChart
          chartData={riskData}
          title="Risk"
          label="category"
          value="count"
        />
      )}
      {peopleData && peopleData.length > 0 && (
        <AADoughnutChart
          chartData={peopleData}
          title="People"
          label="category"
          value="count"
        />
      )}
      {locationData && locationData.length > 0 && (
        <AADoughnutChart
          chartData={locationData}
          title="Location"
          label="category"
          value="count"
        />
      )}
      {miscellaneousData && miscellaneousData.length > 0 && (
        <AADoughnutChart
          chartData={miscellaneousData}
          title="Miscellaneous"
          label="category"
          value="count"
        />
      )}
    </Grid>
  );
}

export default DashboardChartsForm;
