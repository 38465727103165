/* #region header */
/**************************************************************************************************
//
//  Description: Security Classifications
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   12.05.21 Sean Flook         WI39345 Added avatar_icon property.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

const SecurityClassification = [
  {
    id: 1,
    classification: -1,
    description: "Unclassified",
    avatar_id: 1,
    avatar_text: "U",
    avatar_icon: "/images/categories/Unclassified.svg",
  },
  {
    id: 2,
    classification: 0,
    description: "Official",
    avatar_id: 2,
    avatar_text: "O",
    avatar_icon: "/images/categories/Official.svg",
  },
  {
    id: 3,
    classification: 3,
    description: "Secret",
    avatar_id: 3,
    avatar_text: "S",
    avatar_icon: "/images/categories/Secret.svg",
  },
  {
    id: 4,
    classification: 4,
    description: "Top Secret",
    avatar_id: 4,
    avatar_text: "T",
    avatar_icon: "/images/categories/TopSecret.svg",
  },
];

export default SecurityClassification;
