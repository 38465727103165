/* #region header */
/**************************************************************************************************
//
//  Description: Main XDM Data page
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Corrected field names.
//    003   10.05.21 Sean Flook         WI39345 Removed DataSource as no longer required.
//    004   10.05.21 Sean Flook         WI39345 Removed DataSource and use text for Template and DataType.
//    005   12.05.21 Sean Flook         WI39345 Use new function to return todays date.
//    006   14.05.21 Sean Flook         WI39345 Pass through the record id and total records as parameters.
//    007   19.05.21 Sean Flook         WI39345 Handle changing records.
//    008   01.06.21 Sean Flook         WI39345 Changes required for new and changed fields.
//    009   09.06.21 Sean Flook         WI39345 Added in authorityId.
//    010   15.06.21 Sean Flook         WI39345 Display save and delete confirmation.
//    011   18.06.21 Sean Flook         WI39345 Handle saving changes in confirmEditLoss.
//    012   28.06.21 Sean Flook         WI39345 When saving a change do not return to the list.
//    013   13.10.21 Sean Flook         WI39823 Changed to use formattedAddress.
//    014   09.03.22 Peter Bryden       WI40103 Added in Symphony API Security Authentication
//    015   22.06.22 Joel Benford       WI39940 Hook up lastUser to CurrentUser context
//    016   17.08.22 Joel Benford       WI40257 Ignore control fields on willLoseData
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  Fragment,
} from "react";
import PropertyContext from "../context/propertyContext";
import CurrentUserContext from "../context/currentUserContext";
import {
  GetAddXDMDataURL,
  GetUpdateXDMDataURL,
  GetDeleteXDMDataURL,
  GetTemplate,
  GetAuthority,
  authBearerHeader,
} from "../configuration/AAConfig";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CheckIcon from "@material-ui/icons/Check";
import XDMDataListForm from "./XDMDataListForm";
import XdmRecordForm from "./XdmRecordForm";
import DataListContext from "../context/DataListContext";
import { useConfirmation } from "../pages/EditConfirmationPage";
import ObjectComparison from "../utils/ObjectComparison";
import { GetTodaysDate } from "./../components/tables/DashboardFormattingUtils";
import { toNumber } from "lodash";
import useStyles from "../utils/AAStyles";

/* #endregion imports */

const XdmDataForm = (props) => {
  const classes = useStyles();
  const propertyContext = useContext(PropertyContext);
  const [xdmData, setXdmData] = useState(props.data);
  const [formData, setFormData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [template, setTemplate] = useState();
  const [authority, setAuthority] = useState();
  const [currentEditState, setCurrentEditState] = useState(false);
  const [saveOpen, setSaveOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [addXDMDataURL, setAddXDMDataURL] = useState();
  const [updateXDMDataURL, setUpdateXDMDataURL] = useState();
  const [deleteXDMDataURL, setDeleteXDMDataURL] = useState();

  const currentForm = useRef();
  const newForm = useRef();
  const deletePkId = useRef(-1);
  const deleteData = useRef({});
  const deleteFormName = useRef("");
  const currentData = useRef({});
  const selectedPkId = useRef(-1);

  const userContext = useContext(CurrentUserContext);
  const userToken = userContext.currentUser
    ? userContext.currentUser.token
    : null;

  const confirmDialog = useConfirmation();

  const handleSaveClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSaveOpen(false);
  };

  const handleDeleteClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDeleteOpen(false);
  };

  function confirmEditLoss(functionOnLoss) {
    // console.log("DEBUG confirmEditLoss", currentEditState);
    if (currentEditState) {
      confirmDialog(currentEditState)
        .then((result) => {
          const changeFormName = currentForm.current;
          if (result === "save") {
            SaveForm(currentData.current, true);
          }
          setCurrentEditState(false);
          functionOnLoss();
          currentForm.current = changeFormName;
        })
        .catch(() => {});
    }

    if (!currentEditState) {
      setCurrentEditState(false);
      functionOnLoss();
    }
  }

  const changeForm = (name, pkId, dataIdx, len, newType = "") => {
    currentForm.current = name;
    newForm.current = newType;
    // console.log("DEBUG changeForm", name, pkId, dataIdx, len);
    setFormData({ name, pkId, idx: dataIdx + 1, len });
  };

  const handleRiskRecordChange = (dataIdx, curData) => {
    if (currentForm.current !== "Risk") currentForm.current = "Risk";
    const pkId = xdmData.risk[dataIdx - 1].pkId;
    currentData.current = curData;
    confirmEditLoss(() =>
      setFormData({
        name: currentForm.current,
        pkId,
        idx: dataIdx,
        len: xdmData.risk.length,
      })
    );
  };

  const handlePeopleRecordChange = (dataIdx, curData) => {
    if (currentForm.current !== "People") currentForm.current = "People";
    const pkId = xdmData.people[dataIdx - 1].pkId;
    currentData.current = curData;
    confirmEditLoss(() =>
      setFormData({
        name: currentForm.current,
        pkId,
        idx: dataIdx,
        len: xdmData.people.length,
      })
    );
  };

  const handleLocationRecordChange = (dataIdx, curData) => {
    if (currentForm.current !== "Location") currentForm.current = "Location";
    const pkId = xdmData.location[dataIdx - 1].pkId;
    currentData.current = curData;
    confirmEditLoss(() =>
      setFormData({
        name: currentForm.current,
        pkId,
        idx: dataIdx,
        len: xdmData.location.length,
      })
    );
  };

  const handleMiscellaneousRecordChange = (dataIdx, curData) => {
    if (currentForm.current !== "Miscellaneous")
      currentForm.current = "Miscellaneous";
    const pkId = xdmData.miscellaneous[dataIdx - 1].pkId;
    currentData.current = curData;
    confirmEditLoss(() =>
      setFormData({
        name: currentForm.current,
        pkId,
        idx: dataIdx,
        len: xdmData.miscellaneous.length,
      })
    );
  };

  const updateRisks = (newData) => {
    const newXDMData = {
      risk: newData,
      people: xdmData.people,
      location: xdmData.location,
      miscellaneous: xdmData.miscellaneous,
    };
    setXdmData(newXDMData);
  };

  const updatePeople = (newData) => {
    const newXDMData = {
      risk: xdmData.risk,
      people: newData,
      location: xdmData.location,
      miscellaneous: xdmData.miscellaneous,
    };
    setXdmData(newXDMData);
  };

  const updateLocations = (newData) => {
    const newXDMData = {
      risk: xdmData.risk,
      people: xdmData.people,
      location: newData,
      miscellaneous: xdmData.miscellaneous,
    };
    setXdmData(newXDMData);
  };

  const updateMiscellaneous = (newData) => {
    const newXDMData = {
      risk: xdmData.risk,
      people: xdmData.people,
      location: xdmData.location,
      miscellaneous: newData,
    };
    setXdmData(newXDMData);
  };

  const updateXDMData = (actionType, actionData, pkId) => {
    // console.log(
    //   "DEBUG updateXDMData",
    //   currentForm.current,
    //   actionType,
    //   actionData,
    //   pkId
    // );

    function updatePropertyContext() {
      propertyContext.onPropertyChange(
        propertyContext.currentProperty.uprn,
        propertyContext.currentProperty.formattedAddress,
        propertyContext.currentProperty.postcode,
        propertyContext.currentProperty.longitude,
        propertyContext.currentProperty.latitude
      );
    }

    function updateXDMArray(xdmArray) {
      xdmArray = xdmArray ? xdmArray : [];
      switch (actionType) {
        case "POST": //CREATE
          xdmArray.push(actionData);
          selectedPkId.current = actionData.pkId;
          updatePropertyContext();
          break;

        case "PUT": //UPDATE
          // console.log("DEBUG updateXDMArray before", xdmArray);
          const arrIdx = xdmArray.findIndex((elem) => elem.pkId === pkId);
          if (arrIdx >= 0) xdmArray[arrIdx] = actionData;
          selectedPkId.current = actionData.pkId;
          // console.log("DEBUG updateXDMArray after", xdmArray);
          break;

        default:
          // DELETE
          xdmArray = xdmArray.filter((x) => x.pkId !== pkId);
          selectedPkId.current = -1;
          updatePropertyContext();
          break;
      }
      return xdmArray;
    }

    // console.log("DEBUG updateXDMData start", currentForm.current);
    switch (currentForm.current) {
      case "Risk":
        let currentRisks = xdmData.risk;
        // console.log("DEBUG updateXDMData before", currentRisks);
        currentRisks = updateXDMArray(currentRisks);
        // console.log("DEBUG updateXDMData after", currentRisks);
        updateRisks(currentRisks);
        break;
      case "People":
        let currentPeople = xdmData.people;
        currentPeople = updateXDMArray(currentPeople);
        updatePeople(currentPeople);
        break;
      case "Location":
        let currentLocations = xdmData.location;
        currentLocations = updateXDMArray(currentLocations);
        updateLocations(currentLocations);
        break;
      default:
        let currentMiscellaneous = xdmData.miscellaneous;
        currentMiscellaneous = updateXDMArray(currentMiscellaneous);
        updateMiscellaneous(currentMiscellaneous);
        break;
    }
  };

  function GetFetchRecord(fetchData) {
    const now = new Date();

    return {
      uprn: Number(fetchData.uprn),
      id: fetchData.id,
      category: fetchData.category,
      name: fetchData.name,
      notes: fetchData.notes,
      originator: fetchData.originator,
      phone: fetchData.phone,
      entryDate: fetchData.entryDate,
      expiryDate: fetchData.expiryDate,
      reviewDate: fetchData.reviewDate,
      securityClassification: fetchData.securityClassification,
      externalId: fetchData.externalId,
      authorityId: fetchData.authorityId,
      role: fetchData.role,
      peopleName: fetchData.peopleName,
      userName: userContext.currentUser.auditName,
      timeStamp: now,
      template: fetchData.template,
      dataType: fetchData.dataType,
    };
  }

  async function SaveForm(saveData, changeFormHandled = false) {
    let validationErrors = [];
    // console.log("DEBUG SaveForm saveData", saveData);

    if (saveData) {
      // console.log("DEBUG SaveForm uprn", saveData.uprn);
      if (!saveData.uprn || saveData.uprn < 1)
        validationErrors.push({
          key: "uprn",
          error: "UPRN is a required field.",
        });

      // console.log("DEBUG SaveForm category", saveData.category);
      if (!saveData.category || saveData.category.length === 0)
        validationErrors.push({
          key: "category",
          error: "Category is a required field.",
        });

      // console.log("DEBUG SaveForm description", saveData.name);
      if (!saveData.name || saveData.name.length === 0)
        validationErrors.push({
          key: "description",
          error: "Description is a required field.",
        });

      if (validationErrors.length === 0) {
        const bAddNewItem = saveData.pkId && saveData.pkId < 0;
        const saveURL = bAddNewItem ? addXDMDataURL : updateXDMDataURL;

        // console.log("DEBUG SaveForm", saveURL, addXDMDataURL, updateXDMDataURL);

        const fetchUrl = bAddNewItem
          ? saveURL.url
          : `${saveURL.url}/${saveData.pkId}`;

        // console.log("DEBUG SaveForm", JSON.stringify(GetFetchRecord(saveData)));

        if (saveURL) {
          // console.log(
          //   "DEBUG SaveForm",
          //   fetchUrl,
          //   saveURL.type,
          //   saveData,
          //   JSON.stringify(GetFetchRecord(saveData))
          // );
          await fetch(fetchUrl, {
            cache: "no-cache",
            headers: authBearerHeader(userToken, true),
            crossDomain: true,
            method: saveURL.type,
            body: JSON.stringify(GetFetchRecord(saveData)),
          })
            .then((res) =>
              res.ok
                ? res
                : res.text().then((text) => {
                    throw new Error(text);
                  })
            )
            .then((res) => (saveURL.type === "POST" ? res.json() : res))
            .then((result) => {
              if (saveURL.type === "POST") {
                // console.log("DEBUG SaveForm POST before updateXDMData", result);
                updateXDMData(
                  saveURL.type,
                  result.cloudEsXtendedDataList[0],
                  saveData.pkId
                );
              } else {
                // console.log("DEBUG SaveForm PUT before updateXDMData", result);

                updateXDMData(saveURL.type, saveData, saveData.pkId);
              }

              setSaveOpen(true);
              if (!changeFormHandled) {
                setCurrentEditState(false);
                if (saveURL.type === "POST") {
                  changeForm("", null, null, null, saveData.dataType);
                }
              }
            })
            .catch((errorMessage) => {
              validationErrors.push({
                key: "dbError",
                error: errorMessage.toString(),
              });
              console.log("ERROR SaveForm", errorMessage, validationErrors);
            });
        }
      }
    }

    return validationErrors;
  }

  function CheckForEdit(srcData, currData) {
    const willLoseData =
      currData.pkId < 0 ||
      !ObjectComparison(srcData, currData, [
        "timeStamp",
        "userName",
        "xrowid",
        "xdatasourceid",
        "xdatasourcekey",
        "searchedfortext",
        "score",
        "crud",
        "deleteId",
        "errorMessage",
        "importFailedRecordStatus",
        "updateId",
        "windowsUserName",
        "xdmQueuedRecord",
      ]);
    //console.log("DEBUG XdmDataForm.willLoseData", willLoseData);
    setCurrentEditState(willLoseData);
  }

  function FormReturn(oldData, newData) {
    currentData.current = newData;
    confirmEditLoss(() => changeForm("", null, null, null));
  }

  function DeleteForm(delData, formName, pkId) {
    if (deleteXDMDataURL && deleteXDMDataURL.url.length > 0) {
      if (pkId && pkId > 0) {
        deletePkId.current = pkId;
        deleteData.current = GetFetchRecord(delData);
        deleteFormName.current = formName;

        setCurrentEditState(false);
        changeForm("", null, null, null);

        currentForm.current = deleteFormName.current;
        // console.log("DEBUG DeleteForm", JSON.stringify(deleteData.current));

        fetch(`${deleteXDMDataURL.url}/${deletePkId.current}`, {
          cache: "no-cache",
          headers: authBearerHeader(userToken, true),
          crossDomain: true,
          method: "DELETE",
          body: JSON.stringify(deleteData.current),
        })
          .then((res) => (res.ok ? res : Promise.reject(res)))
          .then(
            (result) => {
              // console.log("DEBUG Delete Fetch", result);
              updateXDMData("DELETE", null, deletePkId.current);
              setDeleteOpen(true);
            },
            (error) => {
              console.log("ERROR DeleteForm", error);
            }
          );
      }
    }
  }

  const renderForm = (formData) => {
    let formComp = null;
    let formName = formData ? formData.name : "";
    const today = GetTodaysDate();
    let data = null;

    // console.log("DEBUG renderForm(0)", formData);
    if (formName && formName.length > 0) {
      if (formData.pkId && formData.pkId > 0) {
        // console.log("DEBUG renderForm(1)", xdmData);
        switch (formName) {
          case "Risk":
            data = xdmData.risk.find((x) => x.pkId === formData.pkId);
            break;
          case "People":
            data = xdmData.people.find((x) => x.pkId === formData.pkId);
            break;
          case "Location":
            data = xdmData.location.find((x) => x.pkId === formData.pkId);
            break;
          default:
            data = xdmData.miscellaneous.find((x) => x.pkId === formData.pkId);
            break;
        }
      } else if (formData.pkId && formData.pkId < 0) {
        data = {
          pkId: -1,
          template: template,
          dataType: formName,
          uprn: propertyContext.currentProperty.uprn,
          authorityId: toNumber(authority),
          externalId: null,
          category: null,
          role: null,
          name: null,
          notes: null,
          originator: null,
          phone: null,
          expiryDate: null,
          entryDate: today,
          reviewDate: null,
          securityClassification: -1,
          peopleName: null,
        };
        // console.log("DEBUG renderForm add", data);
      }
    }

    // console.log("DEBUG renderForm(2)", data);

    switch (formName) {
      case "Risk":
        formComp = (
          <XdmRecordForm
            data={data}
            title="Risk record"
            recordNo={formData.idx}
            totalRecords={formData.len}
            template={template}
            onReturnClick={(oldData, newData) => FormReturn(oldData, newData)}
            onSaveClick={(saveData) => SaveForm(saveData)}
            onDeleteClick={(delData, pkId) =>
              DeleteForm(delData, formName, pkId)
            }
            onRecordChange={(dataIdx, curData) =>
              handleRiskRecordChange(dataIdx, curData)
            }
            logEdit={(sourceData, currentData) =>
              CheckForEdit(sourceData, currentData)
            }
          />
        );
        break;

      case "People":
        formComp = (
          <XdmRecordForm
            data={data}
            title="People record"
            recordNo={formData.idx}
            totalRecords={formData.len}
            template={template}
            onReturnClick={(oldData, newData) => FormReturn(oldData, newData)}
            onSaveClick={(saveData) => SaveForm(saveData)}
            onDeleteClick={(delData, pkId) =>
              DeleteForm(delData, formName, pkId)
            }
            onRecordChange={(dataIdx, curData) =>
              handlePeopleRecordChange(dataIdx, curData)
            }
            logEdit={(sourceData, currentData) =>
              CheckForEdit(sourceData, currentData)
            }
          />
        );
        break;

      case "Location":
        formComp = (
          <XdmRecordForm
            data={data}
            title="Location record"
            recordNo={formData.idx}
            totalRecords={formData.len}
            template={template}
            onReturnClick={(oldData, newData) => FormReturn(oldData, newData)}
            onSaveClick={(saveData) => SaveForm(saveData)}
            onDeleteClick={(delData, pkId) =>
              DeleteForm(delData, formName, pkId)
            }
            onRecordChange={(dataIdx, curData) =>
              handleLocationRecordChange(dataIdx, curData)
            }
            logEdit={(sourceData, currentData) =>
              CheckForEdit(sourceData, currentData)
            }
          />
        );
        break;

      case "Miscellaneous":
        formComp = (
          <XdmRecordForm
            data={data}
            title="Miscellaneous record"
            recordNo={formData.idx}
            totalRecords={formData.len}
            template={template}
            onReturnClick={(oldData, newData) => FormReturn(oldData, newData)}
            onSaveClick={(saveData) => SaveForm(saveData)}
            onDeleteClick={(delData, pkId) =>
              DeleteForm(delData, formName, pkId)
            }
            onRecordChange={(dataIdx, curData) =>
              handleMiscellaneousRecordChange(dataIdx, curData)
            }
            logEdit={(sourceData, currentData) =>
              CheckForEdit(sourceData, currentData)
            }
          />
        );
        break;

      default:
        formComp = (
          <XDMDataListForm
            xdmData={xdmData}
            template={template}
            expandedItem={newForm.current}
            selectedItem={selectedPkId.current}
            handleDetailsClick={(formType, formData, dataIdx, dataLength) =>
              changeForm(formType, formData, dataIdx, dataLength)
            }
          />
        );
        break;
    }

    return (
      <Fragment>
        <DataListContext>{formComp}</DataListContext>
        <div>
          <Snackbar
            open={saveOpen}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            onClose={handleSaveClose}
          >
            <Alert
              className={classes.actionAlert}
              icon={<CheckIcon fontSize="inherit" />}
              onClose={handleSaveClose}
              severity="success"
              elevation={6}
              variant="filled"
            >
              Record saved successfully.
            </Alert>
          </Snackbar>
        </div>
        <div>
          <Snackbar
            open={deleteOpen}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            onClose={handleDeleteClose}
          >
            <Alert
              className={classes.actionAlert}
              icon={<CheckIcon fontSize="inherit" />}
              onClose={handleDeleteClose}
              severity="success"
              elevation={6}
              variant="filled"
            >
              Record deleted.
            </Alert>
          </Snackbar>
        </div>
      </Fragment>
    );
  };

  useEffect(() => {
    async function SetupAddXDMDataURL() {
      if (!addXDMDataURL || addXDMDataURL.length === 0) {
        const addURL = await GetAddXDMDataURL();
        setAddXDMDataURL(addURL);
      } else {
        console.log("SetUpApi - addXDMDataURL not null", addXDMDataURL);
      }
    }

    async function SetupUpdateXDMDataURL() {
      if (!updateXDMDataURL || updateXDMDataURL.length === 0) {
        const updateURL = await GetUpdateXDMDataURL();
        setUpdateXDMDataURL(updateURL);
      } else {
        console.log("SetUpApi - updateXDMDataURL not null", updateXDMDataURL);
      }
    }

    async function SetupDeleteXDMDataURL() {
      if (!deleteXDMDataURL || deleteXDMDataURL.length === 0) {
        const deleteURL = await GetDeleteXDMDataURL();
        setDeleteXDMDataURL(deleteURL);
      } else {
        console.log("SetUpApi - deleteXDMDataURL not null", deleteXDMDataURL);
      }
    }

    async function SetupTemplate() {
      if (!template || template.length === 0) {
        const temp = await GetTemplate();
        setTemplate(temp);
      } else {
        console.log("SetUpApi - Template not null", template);
      }
    }

    async function SetupAuthority() {
      if (!authority || authority === 0) {
        const auth = await GetAuthority();
        setAuthority(auth);
      } else {
        console.log("SetUpApi - Authority not null", authority);
      }
    }

    if (!addXDMDataURL || addXDMDataURL.length === 0) SetupAddXDMDataURL();

    if (!updateXDMDataURL || updateXDMDataURL.length === 0)
      SetupUpdateXDMDataURL();

    if (!deleteXDMDataURL || deleteXDMDataURL.length === 0)
      SetupDeleteXDMDataURL();

    if (!template || template.length === 0) SetupTemplate();

    if (!authority || authority === 0) SetupAuthority();
  }, [
    isLoaded,
    addXDMDataURL,
    updateXDMDataURL,
    deleteXDMDataURL,
    template,
    authority,
  ]);

  if (!isLoaded) setIsLoaded(true);

  return isLoaded ? renderForm(formData) : "Loading XDM Data";
};

export default XdmDataForm;
