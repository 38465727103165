//#region header */
/**************************************************************************************************
//
//  Description: All the styling used by the app
//
//  Copyright:    © 2021-22 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   10.05.21 Sean Flook         WI39345 Tweaks required for the dashboard.
//    004   12.05.21 Sean Flook         WI39345 Various styling changes.
//    005   14.05.21 Sean Flook         WI39345 Various styling changes.
//    006   19.05.21 Sean Flook         WI39345 Added styling for list menu box.
//    007   19.05.21 Sean Flook         WI39345 Changed some of the styling for the list items.
//    008   20.05.21 Sean Flook         WI39345 various styling changes.
//    009   01.06.21 Sean Flook         WI39345 Further styling changes.
//    010   02.06.21 Sean Flook         WI39345 Added styling for help drawer.
//    011   08.06.21 Sean Flook         WI39345 Added further styling for help drawer.
//    012   16.06.21 Sean Flook         WI39345 Various changes after UX review.
//    013   17.06.21 Sean Flook         WI39345 Changes required to correctly style the search.
//    014   17.06.21 Sean Flook         WI39345 Updated grey styling.
//    015   28.06.21 Sean Flook         WI39345 Set colour for buttons. Set radius on number avatars.
//    016   01.07.21 Sean Flook         WI39345 Correctly handle the hover colours. Changes required for numbers in avatars.
//    017   19.08.22 Joel Benford       WI40077 Add formReadOnlyText for text (i.e. not readonly date pickers etc)
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */

import { fade, makeStyles } from "@material-ui/core/styles";
import {
  brown,
  yellow,
  pink,
  green,
  blue,
  red,
  deepPurple,
  grey,
  cyan,
  orange,
} from "@material-ui/core/colors";

/* #endregion imports */

const navBarWidth = 70;
const helpBarWidth = 400;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  appBar: {
    width: `calc(100% - ${navBarWidth}px)`,
    marginLeft: navBarWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "#2A6EBB",
  },

  appBarShift: {
    width: `calc(100% - ${navBarWidth}px - ${helpBarWidth}px)`,
    marginLeft: navBarWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: helpBarWidth,
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "#2A6EBB",
  },

  appBar__toolbar: {
    paddingLeft: theme.spacing(0.85),
  },

  appBar__root: {
    flexGrow: 1,
  },

  appBar__menuButton: {
    marginRight: theme.spacing(2),
  },

  appBar__title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  appBar__search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },

  appBar__hide: {
    display: "none",
  },

  appBar__drawer: {
    width: helpBarWidth,
    anchor: "right",
    flexShrink: 0,
  },

  appBar__drawerPaper: {
    width: helpBarWidth,
  },

  appBar__drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },

  appBar__content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -helpBarWidth,
  },

  appBar__contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },

  search__root: {
    color: "inherit",
  },

  search__icon: {
    color: theme.palette.background.contrastText,
  },

  search__input: {
    color: theme.palette.background.contrastText,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    display: "inline-flex",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "30ch",
      "&:focus": {
        width: "40ch",
      },
    },
  },

  search__div: {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#d7d7d7",
    borderRadius: "18px",
    display: "inline",
    height: "35px",
  },

  helpGrid: {
    paddingLeft: theme.spacing(2),
  },

  helpTitle: {
    paddingTop: theme.spacing(1),
  },

  helpRow: {
    paddingTop: theme.spacing(1),
  },

  helpRowTitle: {
    paddingTop: theme.spacing(2),
  },

  helpLink: {
    display: "inline-flex",
  },

  navBar__drawer: {
    width: navBarWidth,
  },

  navBarContent: {
    paddingTop: theme.spacing(2),
    width: navBarWidth,
    height: "98vh",
  },

  navBar__drawerPaper: {
    width: navBarWidth,
  },

  navBar__header: {
    width: navBarWidth,
  },

  // necessary for content to be below app bar
  content__toolbar: theme.mixins.toolbar,

  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0),
  },

  helpIcon: {
    color: "#535353",
    display: "inline-flex",
  },

  helpSubtitle: {
    color: "#797979",
  },

  helpText: {
    color: "#535353",
  },

  actionIcon: {
    color: "#535353",
  },

  actionIconHover: {
    color: "#2A6EBB",
  },

  actionIconSmall: {
    width: "10px",
    height: "10px",
  },

  actionLabel: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    color: "#535353",
  },

  actionAlert: {
    backgroundColor: "#2A6EBB",
  },

  toolbarButton: {
    color: "#FFFFFF",
    backgroundColor: "#2A6EBB",
    "&:hover": {
      backgroundColor: "#00AEEF",
      color: "#FFFFFF",
    },
    marginLeft: theme.spacing(1),
  },

  toolbarButtonDisabled: {
    color: "#FFFFFF",
    backgroundColor: theme.palette.text.disabled,
    marginLeft: theme.spacing(1),
  },

  menuIcon: {
    paddingRight: theme.spacing(1),
  },

  menuText: {
    paddingLeft: theme.spacing(1),
  },

  selectMenuImage: {
    verticalAlign: "middle",
    display: "inline-flex",
  },

  selectMenuLabel: {
    verticalAlign: "middle",
    display: "inline-flex",
  },

  formgrid: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },

  form__header: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1),
    width: "100%",
    backgroundColor: "#ffffff",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "#d7d7d7",
  },

  formToolbarText: {
    marginTop: theme.spacing(0.3),
  },

  form__recordNavigation: {
    paddingTop: theme.spacing(2),
  },

  formrow: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "flex-start",
  },

  formReadOnly: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(1.75),
  },

  formReadOnlyText: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
  },

  formInput: {
    backgroundColor: theme.palette.background.paper,
  },

  formSelect: {
    display: "inline-flex",
    backgroundColor: theme.palette.background.paper,
  },

  outlinedInput: {
    "&$outlinedInputFocused $notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  outlinedInputFocused: {},

  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#e1e1e1 !important",
  },

  map: {
    flexGrow: 0,
    padding: theme.spacing(1),
    height: "93vh",
  },

  mapProgress: {
    position: "absolute",
    top: "50%",
    left: "60%",
  },

  xdmDataForm__header: {
    width: "100%",
    backgroundColor: grey[100],
  },

  xdmDataForm: {
    overflowY: "auto",
    width: "100%",
    height: "84vh",
  },

  list__root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(0),
  },

  toolbar: {
    backgroundColor: "#ffffff",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "#d7d7d7",
  },

  list_listItem: {
    height: "50px",
  },

  listTypeIcon: {
    minWidth: 32,
  },

  listAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },

  list__small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    paddingTop: theme.spacing(0.35),
    borderRadius: "18px",
    fontFamily: "Open Sans",
  },

  list__wide: {
    width: theme.spacing(3),
    height: theme.spacing(2),
    paddingTop: theme.spacing(0.35),
    borderRadius: "18px",
    fontFamily: "Open Sans",
  },

  list__very_wide: {
    width: theme.spacing(4),
    height: theme.spacing(2),
    paddingTop: theme.spacing(0.35),
    borderRadius: "18px",
    fontFamily: "Open Sans",
  },

  list__nested: {
    paddingLeft: theme.spacing(4),
  },

  list__nested_1: {
    paddingLeft: theme.spacing(7),
    backgroundColor: grey[100],
  },

  phoneControl: {
    marginTop: 0,
  },

  datePicker: {
    marginTop: 0,
  },

  maskedInput: {
    paddingLeft: theme.spacing(2),
  },

  homePage: {
    width: `calc(98vw - ${navBarWidth}px)`,
    marginTop: theme.spacing(2),
  },

  dashboardCharts: {
    paddingTop: theme.spacing(2),
  },

  dashboardTables: {
    marginTop: theme.spacing(8),
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: grey[400],
    height: "45vh",
  },

  dashboardOverdueIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    color: red[600],
  },

  dashboardOverdueIcon__small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
    paddingTop: theme.spacing(0.35),
    borderRadius: "18px",
    fontFamily: "Open Sans",
  },

  dashboardOverdueIcon__wide: {
    width: theme.spacing(3),
    height: theme.spacing(2),
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
    paddingTop: theme.spacing(0.35),
    borderRadius: "18px",
    fontFamily: "Open Sans",
  },

  dashboardOverdueIcon__very_wide: {
    width: theme.spacing(4),
    height: theme.spacing(2),
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
    paddingTop: theme.spacing(0.35),
    borderRadius: "18px",
    fontFamily: "Open Sans",
  },

  dashboardHiddenIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: "transparent",
  },

  dashboardIcon: {
    color: grey[400],
  },

  tableDashboard: {
    width: "89vw",
    height: "37vh",
    overflowY: "auto",
    padding: 0,
    "& .table-date-overdue": {
      color: "red",
    },
    "& .table-category": {
      paddingLeft: theme.spacing(2),
    },
  },

  dashboardTable: {
    "& .dashboardRow": {
      "&:hover": {
        backgroundColor: "#DFF1F9",
        color: "#2A6EBB",
        cursor: "pointer",
      },
    },
  },

  overdueDate: {
    color: red[600],
  },

  list_date: {
    width: 120,
  },

  listWarning: {
    color: red[600],
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginBottom: theme.spacing(0.75),
    marginRight: theme.spacing(1),
  },

  dialog_subtitle: {
    paddingBottom: theme.spacing(2),
  },

  dialogButton: {
    color: "#2A6EBB",
    "&:hover": {
      backgroundColor: "#00AEEF",
      color: "#FFFFFF",
    },
  },

  dialogButtonContained: {
    color: "#FFFFFF",
    backgroundColor: "#2A6EBB",
    "&:hover": {
      backgroundColor: "#00AEEF",
      color: "#FFFFFF",
    },
  },

  dialogButtonDelete: {
    color: "#FFFFFF",
    backgroundColor: "#C4051C",
    "&:hover": {
      backgroundColor: "#00AEEF",
      color: "#FFFFFF",
    },
  },

  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },

  brown: {
    color: theme.palette.getContrastText(brown[500]),
    backgroundColor: brown[500],
  },

  yellow: {
    color: theme.palette.getContrastText(yellow[500]),
    backgroundColor: yellow[500],
  },

  green: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[500],
  },

  blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },

  red: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
  },

  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },

  orange: {
    color: theme.palette.getContrastText(orange[900]),
    backgroundColor: orange[900],
  },

  cyan: {
    color: theme.palette.getContrastText(cyan[500]),
    backgroundColor: cyan[500],
  },

  grey: {
    color: "#535353",
    backgroundColor: "#d7d7d7",
  },

  textPink: {
    color: pink[500],
  },

  textBrown: {
    color: brown[500],
  },

  textYellow: {
    color: yellow[500],
  },

  textGreen: {
    color: green[500],
  },

  textBlue: {
    color: blue[500],
  },

  textRed: {
    color: red[600],
  },

  textPurple: {
    color: deepPurple[500],
  },

  textOrange: {
    color: orange[900],
  },

  textCyan: {
    color: cyan[500],
  },

  textGrey: {
    color: grey[500],
  },
}));

export default useStyles;
