//#region header */
/**************************************************************************************************
//
//  Description: Login form
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue#  Description
//#region Version 1.0.0.0 changes
//    001   24.06.21 Joel Benford        WI39518 Initial Revision.
//    002   10.08.21 Joel Benford        WI39518 Interim check-in for new SymphonySecurity API
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React, { useState } from "react";
import {
  FormGroup,
  FormControl,
  FormLabel,
  Button,
  InputLabel,
  Input,
  Typography,
} from "@material-ui/core";
//import Typography from "@material-ui/core/Typography";
import useStyles from "../utils/AAStyles";
//import { composeClasses } from "@material-ui/data-grid";
/* #endregion imports */

const LoginForm = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({ email: "", password: "" });

  const handleChange = (e) =>
    setState((prevState) => ({ ...prevState, [e.target.id]: e.target.value }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    props.onSubmit(state.email, state.password);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} className={classes.homePage}>
        <FormLabel>Please login:</FormLabel>
        <FormGroup>
          <FormControl>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              type="email"
              id="email"
              value={state.email}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              type="password"
              id="password"
              value={state.password}
              onChange={handleChange}
            />
          </FormControl>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            className={classes.Button}
          >
            Login
          </Button>
        </FormGroup>
      </form>
      <Typography>{props.message}</Typography>
    </React.Fragment>
  );
};

export default LoginForm;
