//#region header */
/**************************************************************************************************
//
//  Description: AADataTypeIcons
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   12.05.21 Sean Flook         WI39345 Initial Revision.
//    002   20.05.21 Sean Flook         WI39345 Added menu icons.
//    003   25.05.21 Sean Flook         WI39345 Added AllIcon.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React from "react";
/* #endregion imports */

export function AllIcon() {
  return <img src="/images/categories/TypeAllGrey.svg" alt="" />;
}

export function RiskIcon({ hover, menu }) {
  if (hover) return <img src="/images/categories/TypeRiskHover.svg" alt="" />;
  else if (menu)
    return <img src="/images/categories/TypeRiskGrey.svg" alt="" />;
  else return <img src="/images/categories/TypeRisk.svg" alt="" />;
}

export function PeopleIcon({ hover, menu }) {
  if (hover) return <img src="/images/categories/TypePeopleHover.svg" alt="" />;
  else if (menu)
    return <img src="/images/categories/TypePeopleGrey.svg" alt="" />;
  else return <img src="/images/categories/TypePeople.svg" alt="" />;
}

export function LocationIcon({ hover, menu }) {
  if (hover)
    return <img src="/images/categories/TypeLocationHover.svg" alt="" />;
  else if (menu)
    return <img src="/images/categories/TypeLocationGrey.svg" alt="" />;
  else return <img src="/images/categories/TypeLocation.svg" alt="" />;
}

export function MiscellaneousIcon({ hover, menu }) {
  if (hover)
    return <img src="/images/categories/TypeMiscellaneousHover.svg" alt="" />;
  if (menu)
    return <img src="/images/categories/TypeMiscellaneousGrey.svg" alt="" />;
  else return <img src="/images/categories/TypeMiscellaneous.svg" alt="" />;
}
