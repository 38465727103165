/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Search component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   12.05.21 Sean Flook         WI39345 Changed to allow the dropdown arrow to be displayed.
//    004   13.05.21 Sean Flook         WI39345 Do not hide the clear button.
//    005   13.05.21 Sean Flook         WI39345 Set the no options text to be more appropriate.
//    006   01.06.21 Sean Flook         WI39345 Added postcode to the property context.
//    007   17.06.21 Sean Flook         WI39345 Format the address and added a rounded border.
//    008   18.06.21 Sean Flook         WI39345 corrected field name.
//    009   18.06.21 Sean Flook         WI39345 Corrected spelling.
//    010   13.10.21 Sean Flook         WI39823 Changed to use formattedAddress.
//    011   15.10.21 Peter Bryden               Updated error in address display after selection.
//    012   09.03.22 Peter Bryden       WI40103	Added in Symphony API Security Authentication
//    013   25.08.22 Joel Benford       WI40269 Tidy Console.logs
//    014   05.09.22 Joel Benford       WI40275 Safe synchronous call to asynch fn returning data
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useContext, useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { GetSearchURL, authBearerHeader } from "../configuration/AAConfig";
import PropertyContext from "../context/propertyContext";
import CurrentUserContext from "../context/currentUserContext";
import useStyles from "../utils/AAStyles";

/* #endregion imports */

const apiFetch = async (url, headers, dataIfAborted, signal) => {
  try {
    const response = await fetch(url, {
      headers: headers,
      crossDomain: true,
      method: "GET",
      signal,
    });

    const data = await response.json();
    return data;
  } catch (err) {
    if (err.name === "AbortError") {
      //console.log("DEBUG Request Aborted ");
      return dataIfAborted;
    }
    return err;
  }
};

function AASearch({ placeholder }) {
  const classes = useStyles();
  const propertyContext = useContext(PropertyContext);
  const [data, setData] = useState([]);
  const [urlDetails, setUrlDetails] = useState(null);
  const [search, setSearch] = useState();

  const userContext = useContext(CurrentUserContext);
  const userToken = userContext.currentUser
    ? userContext.currentUser.token
    : null;

  const GetApiSite = async () => {
    if (!urlDetails) {
      const url = await GetSearchURL();
      setUrlDetails(url);
    }
  };

  function addressToTitleCase(address, postcode) {
    const retAddress =
      !address || address.length === 0
        ? address
        : address
            .replace(postcode, "")
            .replace(/[\r\n]/gm, ", ")
            .replaceAll(", , ", ", ")
            .replaceAll(", , ", ", ")
            .replace(/\w\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }) + postcode;

    //console.log("DEBUG addressToTitleCase ", address, retAddress);

    return retAddress;
  }

  useEffect(() => {
    //no-result wrapper can safely be called synchronously to run async function returning a value
    async function fetchAsyncAndSetData(url, whileWaiting, signal) {
      try {
        const result = await apiFetch(
          url,
          authBearerHeader(userToken),
          whileWaiting,
          signal
        );
        setData(result);
      } catch (err) {
        console.log("Error fetching search result from API", err);
      }
    }

    const controller = new AbortController();

    if (urlDetails && search && search.length > 0) {
      const whileWaiting = [
        { uprn: 0, address: "Loading Data...", postcode: "" },
      ];

      setData(whileWaiting);

      const signal = controller.signal;

      const url = `${urlDetails.url}/${search}?nMaxRecords=${urlDetails.info.maxRecords}`;
      fetchAsyncAndSetData(url, whileWaiting, signal);
    } else {
      //console.log("DEBUG no filter data");
      setData([]);
    }

    return () => {
      setData([{ uprn: 0, address: "Loading Data...", postcode: "" }]);
      controller.abort();
    };
  }, [search, urlDetails, userToken]);

  const onSearchChange = (event, newInputValue) => {
    if (!urlDetails) GetApiSite();

    setSearch(newInputValue);
  };

  const onSelectProperty = (event, newValue) => {
    // console.log("DEBUG onSelectProperty", newValue);
    if (newValue)
      propertyContext.onPropertyChange(
        newValue.uprn,
        newValue.formattedaddress,
        newValue.postcode,
        newValue.longitude,
        newValue.latitude
      );
  };

  return userToken ? (
    <Autocomplete
      id="aa-search"
      classes={classes.search__root}
      getOptionLabel={(option) =>
        addressToTitleCase(option.formattedaddress, option.postcode)
      }
      filterOptions={(x) => x}
      noOptionsText="No properties"
      options={data}
      onChange={onSelectProperty}
      onInputChange={onSearchChange}
      renderOption={(option) =>
        addressToTitleCase(option.formattedaddress, option.postcode)
      }
      renderInput={(params) => (
        <div className={classes.search__div}>
          <TextField
            {...params}
            placeholder={placeholder}
            className={classes.search__input}
            margin="normal"
            InputProps={{
              ...params.InputProps,
              disableUnderline: "true",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.search__icon} />
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}
    />
  ) : (
    <div hidden />
  );
}

export default AASearch;
