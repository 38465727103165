//#region header */
/**************************************************************************************************
//
//  Description: Edit Confirmation Page
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   16.06.21 Sean Flook         WI39345 Changes from UX review.
//    003   18.06.21 Sean Flook         WI39345 Pass value to determine if the user has clicked save or discard changes.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React, {
  useState,
  useRef,
  useContext,
  createContext,
  Fragment,
} from "react";
import ConfirmEditLossDialog from "./../dialogs/ConfirmEditLossDialog";
/* #endregion imports */

const ConfirmationServiceContext = createContext();

export const useConfirmation = () => useContext(ConfirmationServiceContext);

export const ConfirmationServiceProvider = ({ children }) => {
  const [confirmationState, setConfirmationState] = useState(null);

  const awaitingPromiseRef = useRef();

  const openConfirmation = (options) => {
    setConfirmationState(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleCancelMoveAway = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const handleSaveChanges = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve("save");
    }

    setConfirmationState(null);
  };

  const handleAllowDispose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve("discard");
    }

    setConfirmationState(null);
  };

  return (
    <Fragment>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={children}
      />

      <ConfirmEditLossDialog
        isOpen={confirmationState}
        title="Unsaved changes"
        message="You have made changes to this record, do you want to save them or discard them?"
        handleSaveClick={handleSaveChanges}
        handleDisposeClick={handleAllowDispose}
        handleReturnClick={handleCancelMoveAway}
        //{...confirmationState}
      />
    </Fragment>
  );
};
