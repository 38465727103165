/* #region header */
/**************************************************************************************************
//
//  Description: Data List Context
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   12.05.21 Sean Flook         WI39345 Changed default to closed for new items.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React, { createContext, useContext, useState, useEffect } from "react";
/* #endregion imports */

const ListStateContext = createContext();
ListStateContext.displayName = "ListStateContext";

const ListStateUpdateContext = createContext();
ListStateUpdateContext.displayName = "ListStateUpdateContext";

export function useListState() {
  return useContext(ListStateContext);
}
export function useListStateUpdate() {
  return useContext(ListStateUpdateContext);
}

function DataListContext({ children }) {
  const [open, setOpen] = useState({});

  function toggleOpen(name) {
    let newOpen = open;
    newOpen[name] = !open[name];
    setOpen(newOpen);
  }

  function getOpen(name) {
    if (open[name] === undefined) {
      let newOpen = open;
      newOpen[name] = false;
      setOpen(newOpen);
    }

    return open[name];
  }

  useEffect(() => {}, [open]);

  return (
    <ListStateContext.Provider value={getOpen}>
      <ListStateUpdateContext.Provider value={toggleOpen}>
        {children}
      </ListStateUpdateContext.Provider>
    </ListStateContext.Provider>
  );
}

export default DataListContext;
