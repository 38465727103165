/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Date component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   14.05.21 Sean Flook         WI39345 Updated className.
//    004   18.05.21 Sean Flook         WI39345 Use the value directly.
//    005   20.05.21 Sean Flook         WI39345 Undo above changes and added new useEffect for the value changing.
//    006   20.05.21 Sean Flook         WI39345 Display a tooltip if required.
//    007   25.05.21 Sean Flook         WI39345 Changes to get the Tooltip to display correctly and include required field text if required to tooltip.
//    008   08.06.21 Sean Flook         WI39345 Changed read-only version to a label and altered colour of outline.
//    009   15.06.21 Sean Flook         WI39345 Display the toolbar as temporary fix, until v5 components are fully released.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import "date-fns";
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Typography, Tooltip } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { format, parseISO } from "date-fns";
import useStyles from "../utils/AAStyles";
import AAErrorDisplay from "./AAErrorDisplay";

/* #endregion imports */

AADateControl.propTypes = {
  label: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  isRequired: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.string,
  errorText: PropTypes.string,
  onChange: PropTypes.func,
};

AADateControl.defaultProps = {
  isEditable: false,
  isRequired: false,
  errorText: "",
};

function AADateControl({
  label,
  isEditable,
  isRequired,
  helperText,
  value,
  errorText,
  onChange,
}) {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(parseISO(value));
  const hasError = useRef(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);

    if (onChange) onChange(date);
  };

  function TextFieldWithTooltip(props) {
    return (
      <Tooltip
        title={
          isRequired ? helperText + " This is a required field." : helperText
        }
        arrow
        placement="right"
      >
        <TextField {...props} />
      </Tooltip>
    );
  }

  useEffect(() => {
    hasError.current = !errorText && errorText !== "" && errorText !== null;
    // console.log("DEBUG AADateControl useEffect", hasError.current, errorText);
  }, [errorText]);

  useEffect(() => {
    setSelectedDate(parseISO(value));
  }, [value]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        className={classes.formrow}
      >
        <Grid item xs={4}>
          <Typography
            variant="body2"
            color={hasError.current ? "error" : "textPrimary"}
            align="left"
          >
            {`${label}${isRequired ? "*" : ""}`}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          {isEditable ? (
            helperText && helperText.length > 0 ? (
              <KeyboardDatePicker
                className={(classes.datePicker, classes.formInput)}
                variant="dialog"
                format="dd MMMM yyyy"
                margin="dense"
                error={hasError.current}
                value={selectedDate}
                initialFocusedDate={selectedDate}
                fullWidth
                autoOk="true"
                size="small"
                inputVariant="outlined"
                disabled={!isEditable}
                required={isRequired}
                helperText={hasError.current && errorText}
                InputProps={{
                  classes: {
                    root: classes.outlinedInput,
                    focused: classes.outlinedInputFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                TextFieldComponent={TextFieldWithTooltip}
              />
            ) : (
              <KeyboardDatePicker
                className={(classes.datePicker, classes.formInput)}
                variant="dialog"
                format="dd MMMM yyyy"
                margin="dense"
                error={hasError.current}
                value={selectedDate}
                initialFocusedDate={selectedDate}
                fullWidth
                size="small"
                autoOk="true"
                inputVariant="outlined"
                disabled={!isEditable}
                required={isRequired}
                helperText={hasError.current && errorText}
                InputProps={{
                  classes: {
                    root: classes.outlinedInput,
                    focused: classes.outlinedInputFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            )
          ) : (
            selectedDate && (
              <Typography
                variant="body1"
                align="left"
                color="textPrimary"
                className={classes.formReadOnly}
              >
                {format(selectedDate, "dd MMMM yyyy")}
              </Typography>
            )
          )}
        </Grid>
        <AAErrorDisplay errorText={errorText} />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export default AADateControl;
