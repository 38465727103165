/* #region header */
/**************************************************************************************************
//
//  Description: Ambulance Risk Categories
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   12.05.21 Sean Flook         WI39345 Added avatar_icon property.
//    003   20.10.21 Sean Flook         WI39823 Ensured we have short hyphens.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

const RiskCategoryAmbulance = [
  {
    id: 1,
    category: "Caution - Allegations",
    avatar_id: 1,
    avatar_text: "CA",
    avatar_icon: "/images/categories/CautionAllegations.svg",
  },
  {
    id: 2,
    category: "Caution - Dangerous Animals",
    avatar_id: 2,
    avatar_text: "CD",
    avatar_icon: "/images/categories/CautionDangerousAnimals.svg",
  },
  {
    id: 3,
    category: "Caution - Medical",
    avatar_id: 3,
    avatar_text: "CM",
    avatar_icon: "/images/categories/CautionMedical.svg",
  },
  {
    id: 4,
    category: "Caution - Other",
    avatar_id: 4,
    avatar_text: "CO",
    avatar_icon: "/images/categories/CautionMedicalOther/svg",
  },
  {
    id: 5,
    category: "Caution - Self Harmer",
    avatar_id: 5,
    avatar_text: "CH",
    avatar_icon: "/images/categories/CautionSelfHarmer.svg",
  },
  {
    id: 6,
    category: "Caution - Sexual",
    avatar_id: 6,
    avatar_text: "CS",
    avatar_icon: "/images/categories/CautionSexual.svg",
  },
  {
    id: 7,
    category: "Caution - Verbally Abusive",
    avatar_id: 7,
    avatar_text: "CV",
    avatar_icon: "/images/categories/CautionVerballyAbusive.svg",
  },
  {
    id: 8,
    category: "Violence - Firearms",
    avatar_id: 8,
    avatar_text: "VF",
    avatar_icon: "/images/categories/ViolenceFirearms.svg",
  },
  {
    id: 9,
    category: "Violence - Person",
    avatar_id: 9,
    avatar_text: "VP",
    avatar_icon: "/images/categories/ViolencePerson.svg",
  },
  {
    id: 10,
    category: "Violence - Weapons",
    avatar_id: 10,
    avatar_text: "VW",
    avatar_icon: "/images/categories/ViolenceWeapons.svg",
  },
];

export default RiskCategoryAmbulance;
