//#region header */
/**************************************************************************************************
//
//  Description: Delete Confirmation Dialog
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   11.06.21 Sean Flook         WI39345 Changes after UX review.
//    003   17.06.21 Sean Flook         WI39345 Set width of dialog.
//    004   18.06.21 Sean Flook         WI39345 Corrected spelling.
//    005   28.06.21 Sean Flook         WI39345 Set the correct colour for the delete button.
//    006   01.07.21 Sean Flook         WI39345 Increased font size.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */

import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "../utils/AAStyles";

/* #endregion imports */

function ConfirmDeleteDialog(props) {
  const { onClose, open, title, subtitle, content, ...other } = props;

  const classes = useStyles();

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="xs"
      open={open}
      aria-labelledby="confirmation-dialog-title"
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        {title ? title : "Delete record?"}
      </DialogTitle>
      <DialogContent>
        {subtitle && (
          <Typography variant="h6" className={classes.dialog_subtitle}>
            {subtitle}
          </Typography>
        )}
        <Typography variant="body1">
          {content ? content : "Are you sure you wish to delete this record?"}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleOk}
          className={classes.dialogButtonDelete}
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          autoFocus
          className={classes.dialogButtonContained}
          onClick={handleCancel}
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDeleteDialog.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ConfirmDeleteDialog;
