/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Navigation Bar Header component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   14.05.21 Sean Flook         WI39345 Added className.
//    003   01.06.21 Sean Flook         WI39345 Removed code that was not required.
//    004   10.06.21 Sean Flook         WI39345 Added onClick event to return to the home screen.
//    005   17.06.21 Sean Flook         WI39345 Added FormattedAddress to property change.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import PropertyContext from "../context/propertyContext";
import { HomeRoute } from "../PageRouting";

/* #endregion imports */

const AANavHeader = (props) => {
  const propertyContext = useContext(PropertyContext);

  const { history } = props;

  const handleClickEvent = () => {
    propertyContext.onPropertyChange(0, "", "", "", 0, 0);
    history.push(HomeRoute);
  };

  return (
    <Grid item xs={12}>
      <img
        src="/images/aligned_assets_logo.svg"
        alt="Aligned Assets"
        width="32"
        height="32"
        onClick={handleClickEvent}
      />
    </Grid>
  );
};

export default AANavHeader;
