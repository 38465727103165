/* #region header */
/**************************************************************************************************
//
//  Description: Receiving Authority DETR Codes
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   01.05.21 Sean Flook         WI39345 Initial Revision.
//    002   01.08.22 Joel Benford       WI40067 Capitalisation
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

const AuthorityCodes = [
  {
    id: 7000,
    authority: "Avon Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7001,
    authority: "Bedfordshire and Luton Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7002,
    authority: "Buckinghamshire and Milton Keynes Fire and Rescue",
    template: "Fire",
  },
  {
    id: 7003,
    authority: "Cambridgeshire Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7004,
    authority: "Central Scotland Fire Brigade",
    template: "Fire",
  },
  {
    id: 7005,
    authority: "Cheshire Fire Service",
    template: "Fire",
  },
  {
    id: 7006,
    authority: "Cleveland Fire Brigade",
    template: "Fire",
  },
  {
    id: 7007,
    authority: "Co Durham and Darlington Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7008,
    authority: "Derbyshire Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7009,
    authority: "Devon Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7010,
    authority: "Dorset Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7011,
    authority: "Dumfries and Galloway Fire Brigade",
    template: "Fire",
  },
  {
    id: 7012,
    authority: "East Sussex Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7013,
    authority: "Essex County Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7014,
    authority: "Fife Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7015,
    authority: "Grampian Fire Brigade",
    template: "Fire",
  },
  {
    id: 7016,
    authority: "Greater Manchester Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7017,
    authority: "Hampshire Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7018,
    authority: "Hereford and Worcester Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7019,
    authority: "Highlands and Islands Fire Brigade",
    template: "Fire",
  },
  {
    id: 7020,
    authority: "Humberside Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7021,
    authority: "Kent Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7022,
    authority: "Lancashire Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7023,
    authority: "Leicestershire Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7024,
    authority: "London Fire and Emergency Planning Authority",
    template: "Fire",
  },
  {
    id: 7025,
    authority: "Lothian and Borders Fire Brigade",
    template: "Fire",
  },
  {
    id: 7026,
    authority: "Merseyside Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7027,
    authority: "Mid and West Wales Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7028,
    authority: "North Wales Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7029,
    authority: "North Yorkshire Fire Authority",
    template: "Fire",
  },
  {
    id: 7030,
    authority: "Nottinghamshire Fire and Rescue",
    template: "Fire",
  },
  {
    id: 7031,
    authority: "Royal Berkshire Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7032,
    authority: "Shropshire Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7033,
    authority: "South Wales Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7034,
    authority: "South Yorkshire Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7035,
    authority: "Staffordshire Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7036,
    authority: "Strathclyde Fire Brigade",
    template: "Fire",
  },
  {
    id: 7037,
    authority: "Tayside Fire Brigade",
    template: "Fire",
  },
  {
    id: 7038,
    authority: "Tyne and Wear Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7039,
    authority: "West Midlands Fire Service",
    template: "Fire",
  },
  {
    id: 7040,
    authority: "West Yorkshire Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7041,
    authority: "Wiltshire Fire Brigade",
    template: "Fire",
  },
  {
    id: 7100,
    authority: "Avon and Somerset Constabulary",
    template: "Police",
  },
  {
    id: 7101,
    authority: "Bedfordshire Police Authority",
    template: "Police",
  },
  {
    id: 7102,
    authority: "British Transport Police",
    template: "Police",
  },
  {
    id: 7103,
    authority: "Cambridgeshire Constabulary",
    template: "Police",
  },
  {
    id: 7104,
    authority: "Central Scotland Police",
    template: "Police",
  },
  {
    id: 7105,
    authority: "Cheshire Constabulary",
    template: "Police",
  },
  {
    id: 7106,
    authority: "City of London Police Authority",
    template: "Police",
  },
  {
    id: 7107,
    authority: "Cleveland Police Authority",
    template: "Police",
  },
  {
    id: 7108,
    authority: "Cumbria Constabulary",
    template: "Police",
  },
  {
    id: 7109,
    authority: "Derbyshire Police",
    template: "Police",
  },
  {
    id: 7110,
    authority: "Devon and Cornwall Constabulary",
    template: "Police",
  },
  {
    id: 7111,
    authority: "Dorset Police",
    template: "Police",
  },
  {
    id: 7112,
    authority: "Dumfries and Galloway Constabulary",
    template: "Police",
  },
  {
    id: 7113,
    authority: "Durham Constabulary",
    template: "Police",
  },
  {
    id: 7114,
    authority: "Dyfed-Powys Police",
    template: "Police",
  },
  {
    id: 7115,
    authority: "Essex Police",
    template: "Police",
  },
  {
    id: 7116,
    authority: "Fife Constabulary",
    template: "Police",
  },
  {
    id: 7117,
    authority: "Gloucestershire Constabulary",
    template: "Police",
  },
  {
    id: 7118,
    authority: "Grampian Police",
    template: "Police",
  },
  {
    id: 7119,
    authority: "Greater Manchester Police",
    template: "Police",
  },
  {
    id: 7120,
    authority: "Gwent Police / Heddlu Gwent",
    template: "Police",
  },
  {
    id: 7121,
    authority: "Hampshire Constabulary",
    template: "Police",
  },
  {
    id: 7122,
    authority: "Hertfordshire Constabulary",
    template: "Police",
  },
  {
    id: 7123,
    authority: "Humberside Police",
    template: "Police",
  },
  {
    id: 7124,
    authority: "Kent Police",
    template: "Police",
  },
  {
    id: 7125,
    authority: "Lancashire Constabulary",
    template: "Police",
  },
  {
    id: 7126,
    authority: "Leicestershire Constabulary Force",
    template: "Police",
  },
  {
    id: 7127,
    authority: "Lincolnshire Police",
    template: "Police",
  },
  {
    id: 7128,
    authority: "Lothian and Borders Police",
    template: "Police",
  },
  {
    id: 7129,
    authority: "Merseyside Police",
    template: "Police",
  },
  {
    id: 7130,
    authority: "Metropolitan Police Authority",
    template: "Police",
  },
  {
    id: 7131,
    authority: "Norfolk Constabulary",
    template: "Police",
  },
  {
    id: 7132,
    authority: "North Wales Police / Heddlu Gogledd Cymru",
    template: "Police",
  },
  {
    id: 7133,
    authority: "North Yorkshire Police",
    template: "Police",
  },
  {
    id: 7134,
    authority: "Northamptonshire Police",
    template: "Police",
  },
  {
    id: 7135,
    authority: "Northern Constabulary",
    template: "Police",
  },
  {
    id: 7136,
    authority: "Northumbria Police",
    template: "Police",
  },
  {
    id: 7137,
    authority: "Nottinghamshire Police",
    template: "Police",
  },
  {
    id: 7138,
    authority: "Scottish Drug Enforcement Agency",
    template: "Police",
  },
  {
    id: 7139,
    authority: "South Wales Police Authority",
    template: "Police",
  },
  {
    id: 7140,
    authority: "South Yorkshire Police",
    template: "Police",
  },
  {
    id: 7141,
    authority: "Staffordshire Police",
    template: "Police",
  },
  {
    id: 7142,
    authority: "Strathclyde Police",
    template: "Police",
  },
  {
    id: 7143,
    authority: "Suffolk Constabulary",
    template: "Police",
  },
  {
    id: 7144,
    authority: "Surrey Police",
    template: "Police",
  },
  {
    id: 7145,
    authority: "Sussex Police Authority",
    template: "Police",
  },
  {
    id: 7146,
    authority: "Tayside Police",
    template: "Police",
  },
  {
    id: 7147,
    authority: "Thames Valley Police",
    template: "Police",
  },
  {
    id: 7148,
    authority: "Warwickshire Police",
    template: "Police",
  },
  {
    id: 7149,
    authority: "West Mercia Constabulary",
    template: "Police",
  },
  {
    id: 7150,
    authority: "West Midlands Police",
    template: "Police",
  },
  {
    id: 7151,
    authority: "West Yorkshire Police",
    template: "Police",
  },
  {
    id: 7152,
    authority: "Wiltshire Constabulary",
    template: "Police",
  },
  {
    id: 7200,
    authority: "Brecon Beacons National Park Authority",
    template: "Park",
  },
  {
    id: 7201,
    authority: "Broads Authority",
    template: "Park",
  },
  {
    id: 7202,
    authority: "Dartmoor National Park",
    template: "Park",
  },
  {
    id: 7203,
    authority: "Exmoor National Park",
    template: "Park",
  },
  {
    id: 7204,
    authority: "Lake District National Park Authority",
    template: "Park",
  },
  {
    id: 7205,
    authority: "North Yorkshire Moors National Park",
    template: "Park",
  },
  {
    id: 7206,
    authority: "Northumberland National Park Authority",
    template: "Park",
  },
  {
    id: 7207,
    authority: "Peak District National Park Authority, The",
    template: "Park",
  },
  {
    id: 7208,
    authority: "Pembrokeshire Coast National Park",
    template: "Park",
  },
  {
    id: 7209,
    authority: "Snowdonia National Park",
    template: "Park",
  },
  {
    id: 7210,
    authority: "Yorkshire Dales National Park Authority",
    template: "Park",
  },
  {
    id: 7211,
    authority: "New Forest National Park",
    template: "Park",
  },
  {
    id: 7300,
    authority: "Chiltern Conservation board",
    template: "Park",
  },
  {
    id: 7301,
    authority: "Cotswold Conservation board",
    template: "Park",
  },
  {
    id: 7302,
    authority: "Lee Valley Regional Park Authority",
    template: "Park",
  },
  {
    id: 7400,
    authority: "Cornwall County Fire Brigade",
    template: "Fire",
  },
  {
    id: 7401,
    authority: "Cumbria Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7402,
    authority: "Gloucestershire Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7403,
    authority: "Hertfordshire Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7404,
    authority: "Isle of Wight Fire and Rescue",
    template: "Fire",
  },
  {
    id: 7405,
    authority: "Lincolnshire Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7406,
    authority: "Norfolk Fire Service",
    template: "Fire",
  },
  {
    id: 7407,
    authority: "Northamptonshire Fire and Rescue",
    template: "Fire",
  },
  {
    id: 7408,
    authority: "Northumberland County Fire and Rescue",
    template: "Fire",
  },
  {
    id: 7409,
    authority: "Oxfordshire Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7410,
    authority: "Somerset Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7411,
    authority: "Suffolk County Fire Service",
    template: "Fire",
  },
  {
    id: 7412,
    authority: "Surrey Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7413,
    authority: "Warwickshire Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7414,
    authority: "West Sussex Fire and Rescue Service",
    template: "Fire",
  },
  {
    id: 7415,
    authority: "West Midlands Regional Control Centre",
    template: "Fire",
  },
  {
    id: 7416,
    authority: "South West Regional Control Centre",
    template: "Fire",
  },
  {
    id: 7417,
    authority: "South East Regional Control Centre",
    template: "Fire",
  },
  {
    id: 7418,
    authority: "Yorkshire and Humberside Regional Control Centre",
    template: "Fire",
  },
  {
    id: 7419,
    authority: "North West Regional Control Centre",
    template: "Fire",
  },
  {
    id: 7420,
    authority: "North East Regional Control Centre",
    template: "Fire",
  },
  {
    id: 7421,
    authority: "East of England Regional Control Centre",
    template: "Fire",
  },
  {
    id: 7422,
    authority: "East Midlands Regional Control Centre",
    template: "Fire",
  },
  {
    id: 7423,
    authority: "London Control Room",
    template: "Fire",
  },
  {
    id: 7655,
    authority: "Ordnance Survey",
    template: "OS",
  },
];

export default AuthorityCodes;
