/* #region header */
/**************************************************************************************************
//
//  Description: Police Miscellaneous Categories
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   12.05.21 Sean Flook         WI39345 Added avatar_icon property.
//    003   20.10.21 Sean Flook         WI39823 Ensured we have short hyphens.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

const MiscellaneousCategoryPolice = [
  {
    id: 1,
    category: "Information - Other",
    avatar_id: 1,
    avatar_text: "IO",
    avatar_icon: "/images/categories/Miscellaneous.svg",
  },
];

export default MiscellaneousCategoryPolice;
