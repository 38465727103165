/* #region header */
/**************************************************************************************************
//
//  Description: API Context
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook                 Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React from "react";
/* #endregion imports */

const ApiContext = React.createContext();
ApiContext.displayName = "ApiContext";

export default ApiContext;
