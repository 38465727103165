//#region header */
/**************************************************************************************************
//
//  Description: Web site version that will be displayed in the GUI
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   05.08.22 Joel Benford       WI39945 Initial Revision.
//    002   05.09.22 Joel Benford       WI40273/40277 Rationalise config, search fixes
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

export const guiVersionBuild = "5.0 beta 2 work in progress of 05 Sep 2022 14:15";
