//#region header */
/**************************************************************************************************
//
//  Description: Main Layout page
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.05.21 Sean Flook         WI39345 Initial Revision.
//    002   25.05.21 Sean Flook         WI39345 Removed reference to Kendo as no longer required.
//    003   01.06.21 Sean Flook         WI39345 Added postcode to the property context.
//    004   02.06.21 Sean Flook         WI39345 Renamed variable to prevent error when deploying.
//    005   08.06.21 Sean Flook         WI39345 Renamed variable again.
//    006   17.06.21 Sean Flook         WI39345 Get the data type counts when the property changes.
//    007   21.06.21 Joel Benford       WI39518 Add CurrentUserContext
//    008   13.10.21 Sean Flook         WI39823 Changed to use formattedAddress.
//	  009	09.03.22 Peter Bryden		WI40103	Added in Symphony API Security Authentication
//    010   08.08.22 Joel Benford       WI39945 Add metadata context
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React, { useState } from "react";
import { Router } from "react-router-dom";
import AAAppBar from "./components/AAAppBar";
import AANavContent from "./components/AANavContent";
import history from "./history";
import PageRouting from "./PageRouting";
import useStyles from "./utils/AAStyles";
import { StylesProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  GetXdmUPRNDataTypeCount,
  authBearerHeader,
} from "./configuration/AAConfig";

import CurrentUserContext from "./context/currentUserContext";
import PropertyContext from "./context/propertyContext";
import ApiContext from "./context/apiContext";
import { MetadataContext, metadataInitial } from "./context/metadataContext";

import "./App.css";
/* #endregion imports */

function App() {
  const classes = useStyles();

  const [currentUser, setCurrentUser] = useState(null);

  const [metadata, setMetadata] = useState(metadataInitial);

  const [xdmProperty, setXdmProperty] = useState({
    uprn: 0,
    formattedAddress: "",
    postcode: "",
    longitude: -0.5337583,
    latitude: 51.3289871,
    riskCount: "0",
    peopleCount: "0",
    locationCount: "0",
    miscellaneousCount: "0",
  });

  const [baseApis, setBaseApis] = useState({
    xdm: "",
    search: "",
    searchKey: "",
    mapKey: "",
  });

  async function handleXdmPropertyChange(
    uprn,
    formattedAddress,
    postcode,
    longitude,
    latitude
  ) {
    const dataTypeUrl = await GetXdmUPRNDataTypeCount();

    if (dataTypeUrl) {
      const userToken = currentUser ? currentUser.token : null;
      const fetchedResult = await fetch(`${dataTypeUrl.url}/${uprn}`, {
        headers: authBearerHeader(userToken, true),
        mode: dataTypeUrl.mode,
        crossDomain: true,
        method: "GET",
      })
        .then((res) => (res.ok ? res : Promise.reject(res)))
        .then((res) => res.json())
        .then(
          (result) => {
            return result ? result : {};
          },
          (error) => {
            console.log("ERROR Getting data type counts", error);
          }
        );

      //console.log("DEBUG FetchDataTypeCounts fetchedResult", fetchedResult);

      setXdmProperty({
        uprn: uprn,
        formattedAddress: formattedAddress,
        postcode: postcode,
        longitude: longitude,
        latitude: latitude,
        riskCount: fetchedResult?.riskCount,
        peopleCount: fetchedResult?.peopleCount,
        locationCount: fetchedResult?.locationCount,
        miscellaneousCount: fetchedResult?.miscellaneousCount,
      });
    }

    if (uprn && uprn > 0) history.push(`/xdm/${uprn}`);
  }

  const handleSettingBaseApis = (xdm, search) => {
    setBaseApis({ xdm, search });
  };

  return (
    <StylesProvider injectFirst>
      <CurrentUserContext.Provider
        value={{ currentUser: currentUser, setCurrentUser: setCurrentUser }}
      >
        <Router history={history}>
          <PropertyContext.Provider
            value={{
              currentProperty: xdmProperty,
              onPropertyChange: handleXdmPropertyChange,
            }}
          >
            <ApiContext.Provider
              value={{
                currentBaseApis: baseApis,
                onSettingBaseApis: handleSettingBaseApis,
              }}
            >
              <MetadataContext.Provider
                value={{ metadata: metadata, setMetadata: setMetadata }}
              >
                <div className={classes.root}>
                  <CssBaseline />
                  <AAAppBar history={history} />
                  <AANavContent uprn={xdmProperty.uprn} history={history} />
                  <main className={classes.content}>
                    <div className={classes.content__toolbar} />
                    <PageRouting />
                  </main>
                </div>
              </MetadataContext.Provider>
            </ApiContext.Provider>
          </PropertyContext.Provider>
        </Router>
      </CurrentUserContext.Provider>
    </StylesProvider>
  );
}

export default App;
