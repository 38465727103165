/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Navigation Bar component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   12.05.21 Sean Flook         WI39345 Removed unused button and removed colour unless cursor is hovering.
//    003   14.05.21 Sean Flook         WI39345 Adjusted the grid justify and alignItems.
//    004   01.06.21 Sean Flook         WI39345 Removed home button and placed the user button at the bottom.
//    005   10.06.21 Sean Flook         WI39345 Pass the history to AANavHeader.
//    006   13.09.21 Sean Flook         WI39345 Changes to grid to get the icons displaying correctly.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";

import AANavHeader from "./AANavHeader";
import AAUserAvatar from "./AAUserAvatar";

import useStyles from "../utils/AAStyles";
/* #endregion imports */

const AANavContent = (props) => {
  const classes = useStyles();

  const { history } = props;

  const handleItemClick = (routeName) => {
    history.push(routeName);
  };

  return (
    <Drawer
      className={classes.navBar__drawer}
      variant="permanent"
      anchor="left"
    >
      <Grid
        className={classes.navBarContent}
        container
        direction="column"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-start"
          >
            <AANavHeader history={history} />
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-end"
          >
            <AAUserAvatar
              handleClick={(routeName) => {
                handleItemClick(routeName);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

AANavContent.propTypes = {};
AANavContent.defaultProps = {};

export default AANavContent;
