/* #region header */
/**************************************************************************************************
//
//  Description: CurrentUser Context
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   21.06.21 Joel Benford        WI39518 Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React from "react";
/* #endregion imports */

const CurrentUserContext = React.createContext();
CurrentUserContext.displayName = "CurrentUserContext";

export default CurrentUserContext;
