//#region header */
/**************************************************************************************************
//
//  Description: Home Page
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   07.05.21 Peter Bryden               Updated UseEffect to prevent page failing if data isn't loaded
//    004   10.05.21 Sean Flook         WI39345 Small changes.
//    005   12.05.21 Sean Flook         WI39345 Added code to display the overdue chart correctly.
//    006   12.05.21 Sean Flook         WI39345 Removed commented out code.
//    007   14.05.21 Sean Flook         WI39345 Changes required to get components to display correctly on page.
//    008   20.05.21 Sean Flook         WI39345 Removed overdue and added miscellaneous chart.
//    009   18.06.21 Sean Flook         WI39345 Corrected spelling.
//    010   30.09.21 Peter Bryden               updated useEffect to stop multiple calls..
//	  011	09.03.22 Peter Bryden		WI40103	Added in Symphony API Security Authentication
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */

import React, { useState, useEffect, useContext } from "react";

import useStyles from "../utils/AAStyles";
import Grid from "@material-ui/core/Grid";
import {
  GetDataTypeCounts,
  GetCategoryCounts,
  GetExpiredRecords,
  GetReviewRecords,
    GetUpdatedRecords,
  authBearerHeader
} from "../configuration/AAConfig";
import DashboardChartsForm from "../forms/DashboardChartsForm";
import DashboardTablesForm from "../forms/DashboardTablesForm";
import CurrentUserContext from "../context/currentUserContext";

/* #endregion imports */

function Homepage() {
  const classes = useStyles();
  const [apiURL, setApiURL] = useState();
  const [allCount, setAllCount] = useState();
  const [riskCount, setRiskCount] = useState();
  const [peopleCount, setPeopleCount] = useState();
  const [locationCount, setLocationCount] = useState();
  const [miscellaneousCount, setMiscellaneousCount] = useState();
  const [expiredRecords, setExpiredRecords] = useState();
  const [reviewRecords, setReviewRecords] = useState();
  const [updatedRecords, setUpdatedRecords] = useState();
  const [loading, setLoading] = useState(false);
  const userContext = useContext(CurrentUserContext);
  const userToken = userContext.currentUser ? userContext.currentUser.token : null;



  useEffect(() => {
    async function SetUpApi() {
      const allCountUrl = await GetDataTypeCounts();
      const categoryCountUrl = await GetCategoryCounts();
      const expiredRecordsUrl = await GetExpiredRecords();
      const reviewRecordsUrl = await GetReviewRecords();
      const updatedRecordsUrl = await GetUpdatedRecords();

      setApiURL({
        allCount: allCountUrl,
        categoryCount: categoryCountUrl,
        expiredRecords: expiredRecordsUrl,
        reviewRecords: reviewRecordsUrl,
        updatedRecords: updatedRecordsUrl,
      });
    }

    if (!apiURL) SetUpApi();

    return () => { };
  }, [apiURL])

  useEffect(() => {

    async function FetchAllCount() {
      if (apiURL && apiURL.allCount) {
        const api_url = apiURL.allCount;
          const fetchedResult = await fetch(`${api_url.url}`, {
              headers: authBearerHeader(userToken,true),
          method: api_url.type,
          mode: api_url.mode,
          crossDomain: true,
        })
          .then((res) => (res.ok ? res : Promise.reject(res)))
          .then((res) => res.json())
          .then(
            (result) => {
              return result ? result : {};
            },
            (error) => {
              console.log("ERROR Getting All Type Count data", error);
            }
          );

        setAllCount(fetchedResult);
      }
    }

    if (!allCount && apiURL) FetchAllCount();

    return () => { };
  }, [apiURL, allCount, userToken]);

  useEffect(() => {
    async function FetchRiskCount() {
      if (apiURL && apiURL.categoryCount) {
        const api_url = apiURL.categoryCount;
        const fetchedResult = await fetch(`${api_url.url}/risk`, {
            headers: authBearerHeader(userToken),
          method: api_url.type,
          mode: api_url.mode,
          crossDomain: true,
        })
          .then((res) => (res.ok ? res : Promise.reject(res)))
          .then((res) => res.json())
          .then(
            (result) => {
              return result ? result : {};
            },
            (error) => {
              console.log("ERROR Getting Risk Count data", error);
              return {};
            }
          );

        setRiskCount(fetchedResult);
      }
    }
 
    if (!riskCount && apiURL) FetchRiskCount();

    return () => { };
  }, [apiURL, riskCount, userToken]);

  useEffect(() => {

    async function FetchPeopleCount() {
      if (apiURL && apiURL.categoryCount) {
        const api_url = apiURL.categoryCount;
        const fetchedResult = await fetch(`${api_url.url}/people`, {
            headers: authBearerHeader(userToken),
          method: api_url.type,
          mode: api_url.mode,
          crossDomain: true,
        })
          .then((res) => (res.ok ? res : Promise.reject(res)))
          .then((res) => res.json())
          .then(
            (result) => {
              return result ? result : [];
            },
            (error) => {
              console.log("ERROR Getting People Count data", error);
              return [];
            }
          );

        setPeopleCount(fetchedResult);
      }
    }

    if (!peopleCount && apiURL) FetchPeopleCount();

    return () => { };
  }, [apiURL,peopleCount, userToken]);

  useEffect(() => {

    async function FetchLocationCount() {
      if (apiURL && apiURL.categoryCount) {
        const api_url = apiURL.categoryCount;
        const fetchedResult = await fetch(`${api_url.url}/location`, {
            headers: authBearerHeader(userToken),
          method: api_url.type,
          mode: api_url.mode,
          crossDomain: true,
        })
          .then((res) => (res.ok ? res : Promise.reject(res)))
          .then((res) => res.json())
          .then(
            (result) => {
              return result ? result : [];
            },
            (error) => {
              console.log("ERROR Getting Location Count data", error);
              return [];
            }
          );

        setLocationCount(fetchedResult);
      }
    }

    if (!locationCount && apiURL) FetchLocationCount();

    return () => { };
  }, [apiURL, locationCount, userToken]);

  useEffect(() => {

    async function FetchMiscellaneousCount() {
      if (apiURL && apiURL.categoryCount) {
        const fetchedResult = await fetch(
          `${apiURL.categoryCount.url}/Miscellaneous`,
          {
            headers: authBearerHeader(userToken,true),
            mode: apiURL.categoryCount.mode,
            crossDomain: true,
            method: "GET",
          }
        )
          .then((res) => (res.ok ? res : Promise.reject(res)))
          .then((res) => res.json())
          .then(
            (result) => {
              return result ? result : []   ;
            },
            (error) => {
              console.log("ERROR Getting Miscellaneous Count data", error);
              return [];
            }
          );

        setMiscellaneousCount(fetchedResult);
      }
    }

    if (!miscellaneousCount && apiURL) FetchMiscellaneousCount();

    return () => { };
  }, [apiURL, miscellaneousCount, userToken]);

  useEffect(() => {
 
    async function FetchExpiredCount() {

      if (apiURL && apiURL.expiredRecords) {
        const api_url = apiURL.expiredRecords;
        const fetchedResult = await fetch(`${api_url.url}`, {
            headers: authBearerHeader(userToken, true),
          method: api_url.type,
          mode: api_url.mode,
          crossDomain: true,
        })
          .then((res) => (res.ok ? res : Promise.reject(res)))
          .then((res) => res.json())
          .then(
            (result) => {
              return result ? result : [];
            },
            (error) => {
              console.log("ERROR Getting Expired Records data", error);
                return [];
            }
          );

        setExpiredRecords(fetchedResult);
      }
    }

    if (!expiredRecords && apiURL) FetchExpiredCount();

    return () => { };
  }, [apiURL, expiredRecords, userToken]);

  useEffect(() => {
    async function FetchReviewCount() {
      if (apiURL && apiURL.reviewRecords) {
        const api_url = apiURL.reviewRecords;
        const fetchedResult = await fetch(`${api_url.url}`, {
            headers: authBearerHeader(userToken,true),
          method: api_url.type,
          mode: api_url.mode,
          crossDomain: true,
        })
          .then((res) => (res.ok ? res : Promise.reject(res)))
          .then((res) => res.json())
          .then(
            (result) => {
              return result ? result : [];
            },
            (error) => {
              console.log("ERROR Getting Review Records data", error);
              return [];
            }
          );

        setReviewRecords(fetchedResult, userToken);
      }
    }

    if (!reviewRecords && apiURL) FetchReviewCount();

    return () => { };
  }, [apiURL, reviewRecords, userToken]);

  useEffect(() => {
      async function FetchUpdatedCount() {
      if (apiURL && apiURL.updatedRecords) {
        const api_url = apiURL.updatedRecords;
        const fetchedResult = await fetch(`${api_url.url}`, {
            headers: authBearerHeader(userToken),
          method: api_url.type,
          mode: api_url.mode,
          crossDomain: true,
        })
          .then((res) => (res.ok ? res : Promise.reject(res)))
          .then((res) => res.json())
          .then(
            (result) => {
              return result ? result : [];
            },
            (error) => {
              console.log("ERROR Getting Updated Records data", error);
              return [];
            }
          );

        setUpdatedRecords(fetchedResult);
      }
    }

    if (!updatedRecords && apiURL) FetchUpdatedCount();

    return () => { };
  }, [apiURL,updatedRecords, userToken]);


  const needsLoading =
    !allCount ||
    !riskCount ||
    !peopleCount ||
    !locationCount ||
    !miscellaneousCount ||
    !expiredRecords ||
    !reviewRecords ||
    !updatedRecords;

  if (needsLoading) {
    if (!loading) {
      setLoading(true);
    }
  } else {
    if (loading) setLoading(false);
  }

  return allCount && !loading ? (
    <div className={classes.homePage}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <DashboardChartsForm
            allData={allCount}
            riskData={riskCount}
            peopleData={peopleCount}
            locationData={locationCount}
            miscellaneousData={miscellaneousCount}
          />
        </Grid>
        <Grid item>
          <DashboardTablesForm
            expiredData={expiredRecords}
            reviewData={reviewRecords}
            updatedData={updatedRecords}
          />
        </Grid>
      </Grid>
    </div>
  ) : (
    <h6>Loading dashboard data...</h6>
  );
}

export default Homepage;
