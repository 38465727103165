/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets XDM Data List Item component
//
//  Copyright:    © 2021-22 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   10.05.21 Sean Flook         WI39345 Corrected icons.
//    004   14.05.21 Sean Flook         WI39345 Only display the add icon on hover and set colour for dates if required.
//    005   19.05.21 Sean Flook         WI39345 Various changes to the display of items in the list.
//    006   19.05.21 Sean Flook         WI39345 Fixed colour for expired dates.
//    007   20.05.21 Sean Flook         WI39345 Only change the colour on the dates.
//    008   24.05.21 Sean Flook         WI39345 Various changes to the design of the items.
//    009   01.06.21 Sean Flook         WI39345 Highlight icons when the cursor is hovering over them.
//    010   11.06.21 Sean Flook         WI39345 Changes required to expand and collapse all items.
//    011   17.06.21 Sean Flook         WI39345 Use rounded avatars rather than circles so they do not look strange if we have to expand them.
//    012   23.06.21 Sean Flook         WI39345 Handle when we do not have a date.
//    013   28.06.21 Sean Flook         WI39345 If we have no expiry date or review date display "n/a". Set the autoFocus to ensure the last viewed item is viewable.
//    014   01.07.21 Sean Flook         WI39345 Added Last updated and sorted the list on this field.
//    015   10.08.22 Joel Benford       WI39932 Show hint to help in record identification
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useState, useEffect, Fragment } from "react";
import useStyles from "../utils/AAStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";

import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import AAActionButton from "./../components/AAActionButton";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { format, parseISO } from "date-fns";
import { FormatDate } from "../components/tables/DashboardFormattingUtils";

import {
  RiskIcon,
  PeopleIcon,
  LocationIcon,
  MiscellaneousIcon,
} from "./AADataTypeIcons";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import WarningIcon from "@material-ui/icons/Warning";

import { useListState, useListStateUpdate } from "../context/DataListContext";

/* #endregion imports */

const AAXDMDataListItem = ({
  title,
  itemIcon,
  data,
  editable,
  itemState,
  itemSelect,
  itemAdd,
}) => {
  const classes = useStyles();

  const getOpen = useListState();
  const toggleOpen = useListStateUpdate();

  const [open, setOpen] = useState(getOpen(title));

  const [itemSelected, setItemSelected] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(data.selectedItem);

  const [riskHover, setRiskHover] = useState(false);
  const [peopleHover, setPeopleHover] = useState(false);
  const [locationHover, setLocationHover] = useState(false);
  const [miscellaneousHover, setMiscellaneousHover] = useState(false);

  const nullDate = "__ ___ ____";

  const handleOpenClose = () => {
    setOpen(!open);
    toggleOpen(title);
  };

  const showListIcon = (icon) => {
    switch (icon) {
      case "Risk":
        return <RiskIcon hover={riskHover} />;
      case "People":
        return <PeopleIcon hover={peopleHover} />;
      case "Location":
        return <LocationIcon hover={locationHover} />;
      default:
        return <MiscellaneousIcon hover={miscellaneousHover} />;
    }
  };

  const handleMouseEnter = () => {
    SetHover(true);
    setItemSelected(true);
  };

  const handleMouseLeave = () => {
    SetHover(false);
    setItemSelected(false);
  };

  const handleMouseEnterRecord = (event) => {
    // console.log("DEBUG handleMouseEnterRecord", event.target.id);
    setSelectedRecord(event.target.id);
  };

  const handleMouseLeaveRecord = () => {
    setSelectedRecord(null);
  };

  function SetHover(hover) {
    if (itemIcon && itemIcon.length > 0) {
      switch (itemIcon) {
        case "Risk":
          setRiskHover(hover);
          break;
        case "People":
          setPeopleHover(hover);
          break;
        case "Location":
          setLocationHover(hover);
          break;
        default:
          setMiscellaneousHover(hover);
          break;
      }
    }
  }

  function ShowWarningIcon(state) {
    switch (state) {
      case "expired":
      case "reviewRequired":
        return <WarningIcon className={classes.listWarning} />;
      default:
        return null;
    }
  }

  function GetAvatarColour(data) {
    if (data.filter((x) => x.state !== "fine").length === 0)
      return classes.grey;
    else return classes.red;
  }

  // console.log("DEBUG AAXDMDataListItem", selectedRecord, data);

  useEffect(() => {
    switch (itemState) {
      case "expanded":
        setOpen(true);
        break;

      case "collapsed":
        setOpen(false);
        break;

      default:
        // Do nothing here
        break;
    }
  }, [itemState]);

  return (
    <Fragment>
      <ListItem
        button
        divider
        dense
        disableGutters
        className={classes.list_listItem}
        selected={itemSelected}
        onClick={handleOpenClose}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {open ? <ExpandMore /> : <ChevronRight />}
        {itemIcon && itemIcon.length > 0 ? (
          <ListItemIcon className={classes.listTypeIcon}>
            {showListIcon(itemIcon)}
          </ListItemIcon>
        ) : (
          ""
        )}
        <ListItemText
          primary={
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography variant="subtitle1">{title}</Typography>
              </Grid>
              <Grid item>
                <Avatar
                  variant="rounded"
                  className={`${
                    data && data.length > 99
                      ? classes.list__very_wide
                      : data && data.length > 9
                      ? classes.list__wide
                      : classes.list__small
                  } ${GetAvatarColour(data)}`}
                >
                  <Typography variant="caption">
                    <strong>{`${data ? data.length : 0}`}</strong>
                  </Typography>
                </Avatar>
              </Grid>
            </Grid>
          }
          className={itemIcon ? "" : classes.nested}
        />
        <ListItemAvatar className={classes.listTypeIcon}>
          {(title !== "Miscellaneous" || editable) && itemSelected && (
            <AAActionButton
              variant="add"
              tooltipTitle={`Add ${title} record`}
              tooltipPlacement="right"
              onClick={() => itemAdd(data ? data.length : 0)}
            />
          )}
        </ListItemAvatar>
      </ListItem>

      <Collapse in={open} timeout="auto">
        {data &&
          data
            .slice()
            .sort(function (a, b) {
              return new Date(b.lastUpdated) - new Date(a.lastUpdated);
            })
            .map((d, index) => (
              <List component="div" disablePadding dense key={`key_${index}`}>
                <ListItem
                  id={d.pkId}
                  alignItems="flex-start"
                  button
                  dense
                  disableGutters
                  autoFocus={
                    d.selectedItem &&
                    d.selectedItem >= 0 &&
                    d.selectedItem.toString() === d.pkId.toString()
                  }
                  selected={
                    selectedRecord &&
                    selectedRecord >= 0 &&
                    selectedRecord.toString() === d.pkId.toString()
                  }
                  className={classes.list__nested_1}
                  onClick={() => itemSelect(d.pkId, index, data.length)}
                  onMouseEnter={handleMouseEnterRecord}
                  onMouseLeave={handleMouseLeaveRecord}
                >
                  <ListItemAvatar className={classes.listTypeIcon}>
                    <Avatar
                      variant="rounded"
                      alt={d.avatarText}
                      src={d.avatarIcon}
                      className={classes.listAvatar}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Fragment>
                        <Typography variant="body1">
                          {`${d.displayText}`}
                        </Typography>
                        <Typography variant="caption">{d.hint}</Typography>
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="flex-end"
                        >
                          <Grid item>
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="flex-end"
                            >
                              <Grid item>
                                <TextField
                                  label="Review"
                                  className={classes.list_date}
                                  size="small"
                                  value={
                                    d.reviewDate
                                      ? format(
                                          parseISO(d.reviewDate),
                                          "dd MMM yyyy"
                                        )
                                      : nullDate
                                  }
                                  margin="dense"
                                  InputProps={{
                                    size: 30,
                                    disableUnderline: true,
                                    readOnly: true,
                                    style: {
                                      color:
                                        d.reviewDate &&
                                        d.state &&
                                        d.state !== "fine" &&
                                        "red",
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <TextField
                                  label="Expiry"
                                  className={classes.list_date}
                                  size="small"
                                  value={
                                    d.expiryDate
                                      ? format(
                                          parseISO(d.expiryDate),
                                          "dd MMM yyyy"
                                        )
                                      : nullDate
                                  }
                                  margin="dense"
                                  InputProps={{
                                    size: 30,
                                    disableUnderline: true,
                                    readOnly: true,
                                    style: {
                                      color:
                                        d.expiryDate &&
                                        d.state &&
                                        d.state === "expired" &&
                                        "red",
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <TextField
                                  label="Last updated"
                                  className={classes.list_date}
                                  size="small"
                                  value={
                                    d.lastUpdated
                                      ? FormatDate(d.lastUpdated)
                                      : nullDate
                                  }
                                  margin="dense"
                                  InputProps={{
                                    size: 30,
                                    disableUnderline: true,
                                    readOnly: true,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            {d.state &&
                              d.state !== "fine" &&
                              ShowWarningIcon(d.state)}
                          </Grid>
                        </Grid>
                      </Fragment>
                    }
                  />
                </ListItem>
              </List>
            ))}
      </Collapse>
    </Fragment>
  );
};

export default AAXDMDataListItem;
