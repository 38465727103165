//#region header */
/**************************************************************************************************
//
//  Description: Login Page
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue#  Description
//#region Version 1.0.0.0 changes
//    001   21.06.21 Joel Benford        WI39518 Initial Revision.
//    002   24.06.21 Joel Benford        WI39518 Class -> functional, added logout
//    003   10.08.21 Joel Benford        WI39518 Interim check-in for new SymphonySecurity API
//    004   03.08.22 Joel Benford        WI40071 Show more user/configuration information
//    004   08.08.22 Joel Benford        WI39945 Add version info
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React, { useState, useContext, useEffect } from "react";
import { HomeRoute } from "../PageRouting";
import CurrentUserContext from "../context/currentUserContext";
import MetadataContext from "../context/metadataContext";
import * as securityApi from "../services/symphonySecurityApi";
import * as metadataService from "../services/metadataService";
import { GetAuthority, GetTemplate } from "../configuration/AAConfig";
import LoginForm from "../forms/LoginForm";
import AuthorityCodes from "../data/AuthorityCodes";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import useStyles from "../utils/AAStyles";
/* #endregion imports */

const Loginpage = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({ message: null });
  const [authority, setAuthority] = useState({
    id: null,
    authority: null,
    template: null,
  });
  const userContext = useContext(CurrentUserContext);
  const metadataContext = useContext(MetadataContext);

  const handleLogin = async (email, password) => {
    const loginResult = await securityApi.authority.login(email, password);
    if (!loginResult) {
      userContext.setCurrentUser(null);
      setState({ message: "Login failed for those details" });
      return;
    }
    const apiUserInfo = await securityApi.authority.whoAmI(loginResult.token);
    if (!apiUserInfo) {
      userContext.setCurrentUser(null);
      setState({ message: "Login ok, but couldn't get user info" });
      return;
    }
    userContext.setCurrentUser({
      ...apiUserInfo,
      token: loginResult.token,
      expiry: loginResult.expiry,
    });

    const newMetadata = await metadataService.version.updateFromApi(
      metadataContext.metadata
    );
    //console.log("DEBUG got metadata", newMetadata);
    metadataContext.setMetadata(newMetadata);

    setState({ message: null });
  };

  const handleLogout = () => userContext.setCurrentUser(null);

  const loadHomepage = () => {
    props.history.push(HomeRoute);
  };

  const user = userContext.currentUser;

  useEffect(() => {
    const getAuthority = async () => {
      if (!authority.id) {
        const detrCode = Number(await GetAuthority());
        const matched = AuthorityCodes.find((a) => a.id === detrCode);
        setAuthority({
          id: detrCode,
          authority: matched
            ? matched.authority
            : "DESCRIPTION NOT FOUND IN AuthorityCodes.js",
          template: await GetTemplate(),
        });
      }
    };
    getAuthority();
  }, [authority]);

  if (user) {
    return (
      <div className={classes.homePage}>
        <Typography variant="h5">Current login:</Typography>
        <Typography>Token expiry: {user.expiry}</Typography>

        <Typography variant="h5">Current user:</Typography>
        <Typography>First name: {user.firstName}</Typography>
        <Typography>Last name: {user.lastName}</Typography>
        <Typography>Audit name: {user.auditName}</Typography>
        <Typography>Email: {user.email}</Typography>
        <Typography>Organisation: {user.organisation}</Typography>
        <Typography>Has rights: {user.rights.map((r) => `${r}, `)}</Typography>

        <Typography variant="h5">Configuration:</Typography>
        <Typography>
          Authority: {authority.id} ({authority.authority})
        </Typography>
        <Typography>Template: {authority.template}</Typography>

        <Typography variant="h5">Versions:</Typography>
        <Typography>
          Web site: {metadataContext.metadata.versionInfo.guiVersion}
        </Typography>
        <Typography>
          XDM Cloud API: {metadataContext.metadata.versionInfo.apiVersion}
        </Typography>
        <Typography>
          XDM database:{" "}
          {metadataContext.metadata.versionInfo.xdmDatabaseVersion}
        </Typography>
        <Typography>
          Bluelight database:{" "}
          {metadataContext.metadata.versionInfo.bluelightDatabaseVersion}
        </Typography>
        <Typography>
          Bluelight iExchange database:{" "}
          {
            metadataContext.metadata.versionInfo
              .bluelightIExchangeDatabaseVersion
          }
        </Typography>
        <Typography>
          Bluelight index built on:{" "}
          {
            metadataContext.metadata.versionInfo.bluelightElasticIndexMetadata
              .builton
          }
        </Typography>
        <Typography>
          XDM index built on:{" "}
          {metadataContext.metadata.versionInfo.xdmElasticIndexMetadata.builton}
        </Typography>
        <Button variant="outlined" onClick={loadHomepage}>
          Homepage
        </Button>
        <Button variant="outlined" onClick={handleLogout}>
          Logout
        </Button>
      </div>
    );
  } else {
    return <LoginForm onSubmit={handleLogin} message={state.message} />;
  }
};

export default Loginpage;
