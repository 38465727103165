/* #region header */
/**************************************************************************************************
//
//  Description: Metadata Context
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   05.08.22 Joel Benford        WI39945 Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React from "react";
import { guiVersionBuild } from "../configuration/AAVersionInfo";
/* #endregion imports */

export const MetadataContext = React.createContext();
MetadataContext.displayName = "MetadataContext";

export const metadataInitial = {
    versionInfo: {
      guiVersion: guiVersionBuild,
      apiVersion: "",
      xdmDatabaseVersion: "",
      bluelightDatabaseVersion: "",
      bluelightIExchangeDatabaseVersion: "",
      bluelightElasticIndexMetadata: { builton: "" },
      xdmElasticIndexMetadata: { builton: "" },
    },
  };

export default MetadataContext;
