/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Select component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   12.05.21 Sean Flook         WI39345 Changed to allow for the display of icons.
//    004   14.05.21 Sean Flook         WI39345 Updated className.
//    005   18.05.21 Sean Flook         WI39345 Use the value directly.
//    006   20.05.21 Sean Flook         WI39345 Display a tooltip if required.
//    007   25.05.21 Sean Flook         WI39345 Include required field text if required to tooltip.
//    008   01.06.21 Sean Flook         WI39345 Ensure the image is inline with the text.
//    009   08.06.21 Sean Flook         WI39345 Changed read-only version to a label and altered colour of outline.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Typography, Tooltip } from "@material-ui/core";
import useStyles from "../utils/AAStyles";
import { Fragment } from "react";
/* #endregion imports */

AASelectControl.propTypes = {
  label: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  isRequired: PropTypes.bool,
  lookupData: PropTypes.array.isRequired,
  lookupId: PropTypes.string.isRequired,
  lookupLabel: PropTypes.string.isRequired,
  lookupIcon: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.string,
  errorText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

AASelectControl.defaultProps = {
  isEditable: false,
  isRequired: false,
  errorText: "",
};

function AASelectControl({
  label,
  isEditable,
  isRequired,
  lookupData,
  lookupId,
  lookupLabel,
  lookupIcon,
  helperText,
  value,
  errorText,
  onChange,
}) {
  const classes = useStyles();
  const hasError = useRef(false);

  const handleChangeEvent = (event) => {
    if (onChange) onChange(event.target.value);
  };

  const getLookupInfo = (value) => {
    if (lookupData) {
      const currentRow = lookupData.filter((x) => x[lookupId] === value);

      if (currentRow) {
        if (
          lookupIcon &&
          currentRow[0][lookupIcon] &&
          currentRow[0][lookupIcon].length !== 0
        ) {
          return (
            <Fragment className={classes.formReadOnly}>
              <img
                src={currentRow[0][lookupIcon]}
                alt=""
                width="20"
                height="20"
              />
              <Typography variant="body1" align="left" color="textPrimary">
                {currentRow[0][lookupLabel]}
              </Typography>
            </Fragment>
          );
        } else {
          return (
            <Typography
              variant="body1"
              align="left"
              color="textPrimary"
              className={classes.formReadOnly}
            >
              {currentRow[0][lookupLabel]}
            </Typography>
          );
        }
      }
    }
  };

  useEffect(() => {
    hasError.current = errorText !== "" && errorText !== null;
  }, [errorText]);

  return (
    <Grid
      container
      justify="flex-start"
      alignItems="center"
      className={classes.formrow}
    >
      <Grid item xs={4}>
        <Typography
          variant="body2"
          color={hasError.current ? "error" : "textPrimary"}
          align="left"
        >
          {`${label}${isRequired ? "*" : ""}`}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        {isEditable ? (
          helperText && helperText.length > 0 ? (
            <Tooltip
              title={
                isRequired
                  ? helperText + " This is a required field."
                  : helperText
              }
              arrow
              placement="right"
            >
              <TextField
                className={(classes.formInput, classes.formSelect)}
                error={hasError.current}
                fullWidth
                select
                disabled={!isEditable}
                required={isRequired}
                defaultValue=""
                variant="outlined"
                margin="dense"
                size="small"
                helperText={hasError.current && errorText}
                value={value}
                onChange={handleChangeEvent}
                InputProps={{
                  alignItems: "center",
                  classes: {
                    root: classes.outlinedInput,
                    focused: classes.outlinedInputFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              >
                {lookupData.map((option) => (
                  <MenuItem key={option[lookupId]} value={option[lookupId]}>
                    {lookupIcon &&
                      option[lookupIcon] &&
                      option[lookupIcon].length !== 0 && (
                        <ListItemIcon className={classes.selectMenuImage}>
                          <img
                            src={option[lookupIcon]}
                            alt=""
                            width="20"
                            height="20"
                          />
                        </ListItemIcon>
                      )}
                    <Typography
                      className={classes.selectMenuLabel}
                      variant="inherit"
                    >
                      {option[lookupLabel]}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
            </Tooltip>
          ) : (
            <TextField
              className={classes.formInput}
              error={hasError.current}
              fullWidth
              select
              disabled={!isEditable}
              required={isRequired}
              defaultValue=""
              variant="outlined"
              margin="dense"
              size="small"
              helperText={hasError.current && errorText}
              value={value}
              onChange={handleChangeEvent}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                  focused: classes.outlinedInputFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            >
              {lookupData.map((option) => (
                <MenuItem key={option[lookupId]} value={option[lookupId]}>
                  {lookupIcon &&
                    option[lookupIcon] &&
                    option[lookupIcon].length !== 0 && (
                      <ListItemIcon>
                        <img
                          src={option[lookupIcon]}
                          alt=""
                          width="20"
                          height="20"
                        />
                      </ListItemIcon>
                    )}
                  <Typography variant="inherit">
                    {option[lookupLabel]}
                  </Typography>
                </MenuItem>
              ))}
            </TextField>
          )
        ) : (
          getLookupInfo(value)
        )}
      </Grid>
    </Grid>
  );
}

export default AASelectControl;
