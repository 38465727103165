/* #region header */
/**************************************************************************************************
//
//  Description: Fire Risk Categories
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   12.05.21 Sean Flook         WI39345 Added avatar_icon property.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

const RiskCategoryFire = [
  {
    xrowId: 1,
    category: "Chemical",
    avatar_id: 1,
    avatar_text: "C",
    avatar_icon: "/images/categories/Chemical.svg",
  },
  {
    xrowId: 2,
    category: "Explosives",
    avatar_id: 2,
    avatar_text: "E",
    avatar_icon: "/images/categories/Explosives.svg",
  },
  {
    xrowId: 3,
    category: "Petroleum",
    avatar_id: 3,
    avatar_text: "Pt",
    avatar_icon: "/images/categories/Petroleum.svg",
  },
  {
    xrowId: 4,
    category: "People",
    avatar_id: 4,
    avatar_text: "Pp",
    avatar_icon: "/images/categories/People.svg",
  },
  {
    xrowId: 5,
    category: "Animal",
    avatar_id: 5,
    avatar_text: "A",
    avatar_icon: "/images/categories/Animals.svg",
  },
  {
    xrowId: 6,
    category: "Radiation",
    avatar_id: 6,
    avatar_text: "R",
    avatar_icon: "/images/categories/Radiation.svg",
  },
  {
    xrowId: 7,
    category: "Fire",
    avatar_id: 7,
    avatar_text: "F",
    avatar_icon: "/images/categories/Fire.svg",
  },
];

export default RiskCategoryFire;
