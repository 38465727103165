/* #region header */
/**************************************************************************************************
//
//  Description: Police People Categories
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   12.05.21 Sean Flook         WI39345 Added avatar_icon property.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

const PeopleCategoryPolice = [
  {
    id: 1,
    category: "Immobile Householder",
    avatar_id: 1,
    avatar_text: "IH",
    avatar_icon: "/images/categories/ImmobileHouseholder.svg",
  },
  {
    id: 2,
    category: "Disabled",
    avatar_id: 2,
    avatar_text: "D",
    avatar_icon: "/images/categories/Disabled.svg",
  },
  {
    id: 3,
    category: "Keyholder",
    avatar_id: 3,
    avatar_text: "K",
    avatar_icon: "/images/categories/Keyholder.svg",
  },
  {
    id: 4,
    category: "Person at Increased Risk",
    avatar_id: 4,
    avatar_text: "PI",
    avatar_icon: "/images/categories/PersonAtIncreasedRisk.svg",
  },
  {
    id: 5,
    category: "Miscellaneous",
    avatar_id: 5,
    avatar_text: "M",
    avatar_icon: "/images/categories/MiscellaneousPeople.svg",
  },
];

export default PeopleCategoryPolice;
