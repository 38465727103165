/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Help Drawer component
//
//  Copyright:    © 2021-22 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   02.06.21 Sean Flook         WI39345 Tidied up the content.
//    003   08.06.21 Sean Flook         WI39345 Modified after UX review.
//    004   16.06.21 Sean Flook         WI39345 Removed divider, corrected spelling and set subject line for emails.
//    005   18.08.22 Joel Benford       WI39946 Add help link to configured URL
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React from "react";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import AAActionButton from "./AAActionButton";
import { Grid } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/HelpOutlined";
import ForumIcon from "@material-ui/icons/ForumOutlined";
import EmailIcon from "@material-ui/icons/EmailOutlined";
import CallIcon from "@material-ui/icons/CallOutlined";
import useStyles from "../utils/AAStyles";

/* #endregion imports */

AAHelpDrawer.propTypes = {
  handleDrawerClose: PropTypes.func.isRequired,
};

function AAHelpDrawer(props) {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.appBar__drawer}
      variant="persistent"
      anchor="right"
      open={props.open}
      classes={{
        paper: classes.appBar__drawerPaper,
      }}
    >
      <div className={classes.appBar__drawerHeader}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h6" noWrap className={classes.helpSubtitle}>
              Help and support
            </Typography>
          </Grid>
          <Grid item>
            <AAActionButton
              variant="close"
              tooltipTitle="Close help"
              tooltipPlacement="left"
              onClick={props.handleDrawerClose}
            />
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        className={classes.helpGrid}
      >
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.helpRow}
        >
          <Grid item xs={1}>
            <HelpIcon className={classes.helpIcon} />
          </Grid>
          <Grid item>
            <Typography align="left" variant="subtitle2">
              Online help
              <br />
              <Link
                align={"left"}
                href={process.env.REACT_APP_HELP_URL}
                target="_blank"
                rel="noopener"
                variant="body2"
              >
                Help documents for XDM Manager
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.helpRow}
        >
          <Grid item xs={1}>
            <ForumIcon className={classes.helpIcon} />
          </Grid>
          <Grid item>
            <Typography align="left" variant="subtitle2">
              Customer portal
              <br />
              <Link
                align={"left"}
                href="https://portal.aligned-assets.co.uk"
                target="_blank"
                rel="noopener"
                variant="body2"
              >
                Browse the Knowledge Base or search forums
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.helpRow}
        >
          <Grid item xs={1}>
            <img
              src="/images/SupportAgent.svg"
              alt=""
              className={classes.helpIcon}
            />
          </Grid>
          <Grid item>
            <Typography align="left" variant="subtitle2">
              Contact support <br />
              <Link
                align={"left"}
                href="mailto:support@aligned-assets.co.uk?subject=Support enquiry raised from XDM Manager Help"
                variant="body2"
              >
                Raise a case with our support team
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.helpRowTitle}>
          <Typography
            align="left"
            variant="subtitle1"
            className={classes.helpSubtitle}
          >
            General enquiries
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.helpRow}
        >
          <Grid item xs={1}>
            <EmailIcon className={classes.helpIcon} />
          </Grid>
          <Grid item className={classes.helpLink}>
            <Link
              align={"left"}
              href="mailto:solutions@aligned-assets.co.uk?subject=Solutions enquiry raised from XDM Manager Help"
              variant="body2"
            >
              solutions@aligned-assets.co.uk
            </Link>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={1}>
            <CallIcon className={classes.helpIcon} />
          </Grid>
          <Grid item>
            <Typography
              align={"left"}
              variant="body2"
              className={classes.helpText}
            >
              +44 (0) 1483 717 950
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.helpRow}>
          <Typography
            align={"left"}
            variant="body2"
            className={classes.helpText}
          >
            Aligned Assets
          </Typography>
          <Typography
            align={"left"}
            variant="body2"
            className={classes.helpText}
          >
            Unit 5 Woking 8
          </Typography>
          <Typography
            align={"left"}
            variant="body2"
            className={classes.helpText}
          >
            Forsyth Road
          </Typography>
          <Typography
            align={"left"}
            variant="body2"
            className={classes.helpText}
          >
            Woking
          </Typography>
          <Typography
            align={"left"}
            variant="body2"
            className={classes.helpText}
          >
            Surrey
          </Typography>
          <Typography
            align={"left"}
            variant="body2"
            className={classes.helpText}
          >
            GU21 5SB
          </Typography>
        </Grid>
      </Grid>
    </Drawer>
  );
}

export default AAHelpDrawer;
