/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Action Button component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   02.06.21 Sean Flook         WI39345 Initial Revision.
//    002   02.06.21 Sean Flook         WI39345 Added home and close action buttons.
//    003   08.06.21 Sean Flook         WI39345 Changed the close icon.
//    004   16.06.21 Sean Flook         WI39345 Added further parameters to control how the buttons are displayed.
//    005   28.06.21 Sean Flook         WI39345 Added disabled colour to icons.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PreviousIcon from "@material-ui/icons/ChevronLeft";
import NextIcon from "@material-ui/icons/ChevronRight";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import CloseIcon from "@material-ui/icons/Close";
import HomeIcon from "@material-ui/icons/ArrowBack";

/* #endregion imports */

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    color: "#535353",
  },

  actionIconDisabled: {
    color: theme.palette.text.disabled,
  },

  actionIconHover: {
    color: "#2A6EBB",
  },
}));

AAActionButton.PropTypes = {
  variant: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  disabled: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  fontSize: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

function AAActionButton({
  variant,
  buttonLabel,
  disabled,
  tooltipTitle,
  tooltipPlacement,
  fontSize,
  onClick,
}) {
  const classes = useStyles();

  const [iconHover, setIconHover] = useState(false);

  const handleIconMouseEnter = () => {
    setIconHover(true);
  };

  const handleIconMouseLeave = () => {
    setIconHover(false);
  };

  const handleClickEvent = () => {
    if (onClick) onClick();
  };

  const renderIcon = () => {
    let iconComp = null;

    switch (variant) {
      case "previous":
        if (fontSize && fontSize !== "") {
          iconComp = (
            <PreviousIcon
              fontSize={fontSize}
              className={clsx({
                [classes.actionIconDisabled]: disabled,
                [classes.actionIcon]: !iconHover,
                [classes.actionIconHover]: iconHover,
              })}
            />
          );
        } else {
          iconComp = (
            <PreviousIcon
              className={clsx({
                [classes.actionIconDisabled]: disabled,
                [classes.actionIcon]: !iconHover,
                [classes.actionIconHover]: iconHover,
              })}
            />
          );
        }

        break;

      case "next":
        if (fontSize && fontSize !== "") {
          iconComp = (
            <NextIcon
              fontSize={fontSize}
              className={clsx({
                [classes.actionIconDisabled]: disabled,
                [classes.actionIcon]: !iconHover,
                [classes.actionIconHover]: iconHover,
              })}
            />
          );
        } else {
          iconComp = (
            <NextIcon
              className={clsx({
                [classes.actionIconDisabled]: disabled,
                [classes.actionIcon]: !iconHover,
                [classes.actionIconHover]: iconHover,
              })}
            />
          );
        }
        break;

      case "save":
        if (fontSize && fontSize !== "") {
          iconComp = (
            <SaveIcon
              fontSize={fontSize}
              className={clsx({
                [classes.actionIconDisabled]: disabled,
                [classes.actionIcon]: !iconHover,
                [classes.actionIconHover]: iconHover,
              })}
            />
          );
        } else {
          iconComp = (
            <SaveIcon
              className={clsx({
                [classes.actionIconDisabled]: disabled,
                [classes.actionIcon]: !iconHover,
                [classes.actionIconHover]: iconHover,
              })}
            />
          );
        }
        break;

      case "delete":
        if (fontSize && fontSize !== "") {
          iconComp = (
            <DeleteIcon
              fontSize={fontSize}
              className={clsx({
                [classes.actionIconDisabled]: disabled,
                [classes.actionIcon]: !iconHover,
                [classes.actionIconHover]: iconHover,
              })}
            />
          );
        } else {
          iconComp = (
            <DeleteIcon
              className={clsx({
                [classes.actionIconDisabled]: disabled,
                [classes.actionIcon]: !iconHover,
                [classes.actionIconHover]: iconHover,
              })}
            />
          );
        }
        break;

      case "return":
      case "home":
        if (fontSize && fontSize !== "") {
          iconComp = (
            <HomeIcon
              fontSize={fontSize}
              className={clsx({
                [classes.actionIconDisabled]: disabled,
                [classes.actionIcon]: !iconHover,
                [classes.actionIconHover]: iconHover,
              })}
            />
          );
        } else {
          iconComp = (
            <HomeIcon
              className={clsx({
                [classes.actionIconDisabled]: disabled,
                [classes.actionIcon]: !iconHover,
                [classes.actionIconHover]: iconHover,
              })}
            />
          );
        }
        break;

      case "close":
        if (fontSize && fontSize !== "") {
          iconComp = (
            <CloseIcon
              fontSize={fontSize}
              className={clsx({
                [classes.actionIconDisabled]: disabled,
                [classes.actionIcon]: !iconHover,
                [classes.actionIconHover]: iconHover,
              })}
            />
          );
        } else {
          iconComp = (
            <CloseIcon
              className={clsx({
                [classes.actionIconDisabled]: disabled,
                [classes.actionIcon]: !iconHover,
                [classes.actionIconHover]: iconHover,
              })}
            />
          );
        }
        break;

      default:
        if (fontSize && fontSize !== "") {
          iconComp = (
            <AddCircleOutlineIcon
              fontSize={fontSize}
              className={clsx({
                [classes.actionIconDisabled]: disabled,
                [classes.actionIcon]: !iconHover,
                [classes.actionIconHover]: iconHover,
              })}
            />
          );
        } else {
          iconComp = (
            <AddCircleOutlineIcon
              className={clsx({
                [classes.actionIconDisabled]: disabled,
                [classes.actionIcon]: !iconHover,
                [classes.actionIconHover]: iconHover,
              })}
            />
          );
        }
        break;
    }

    return (
      <IconButton
        size="small"
        disabled={disabled}
        onClick={handleClickEvent}
        onMouseEnter={handleIconMouseEnter}
        onMouseLeave={handleIconMouseLeave}
        aria-label={variant}
      >
        <Tooltip title={tooltipTitle} arrow placement={tooltipPlacement}>
          {iconComp}
        </Tooltip>
        {buttonLabel && buttonLabel.length > 0 && (
          <Typography variant="body2" className={classes.actionLabel}>
            {buttonLabel}
          </Typography>
        )}
      </IconButton>
    );
  };

  return renderIcon();
}

export default AAActionButton;
